import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import BasicModal from '../BasicModal';
import DragDropInput from './DragDropInput';
import { Button, IconButton } from '@mui/material';
import CropIcon from '@mui/icons-material/Crop';
import ClearIcon from '@mui/icons-material/Clear';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { validateFileSize, validateFileType } from './filesUtils';
import { toast } from 'react-toastify';

export const ImageCropPicker = ({
  label,
  defaultImage = null,
  setFile = () => {},
  onClear = () => {},
  ratio = 4 / 5,
  helperText,
  maxSize = 2,
  error,
  placeholder,
}) => {
  // this will be used to trigger upload files when button clicked
  const fileInputRef = useRef(null);
  // image source ( data url ) for preview, example preview image in update page
  const [image, setImage] = useState(defaultImage);
  // cropper object instance // we can use ref instead of state
  const [cropper, setCropper] = useState();
  // first time the cropper will be hidden and become visible when input is selected
  const [cropperIsVisible, showCropper] = useState(false);
  // if default image is set then we set it to true else it will be false by default
  const [didSelectCroppedImage, setDidSelectCroppedImage] = useState(
    !!defaultImage
  );

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (!files || files.length === 0) return;

    if (!validateFileType(files[0])) {
      return toast.error('ملف غير مدعوم');
    }
    if (!validateFileSize(files[0], maxSize)) {
      return toast.error(`حجم الصور يجب ان لا يتجاوز ${maxSize} ميجا`);
    }

    readImageFromFile(files);
    showCropper(true);
    // reset crop selection state when user pick new image in order to always force image crop
    setDidSelectCroppedImage(false);
    setFile(undefined);
    // this is to reset input state
    // we need to reset it to allow user to select
    // same file again
    // if u disable this onChange will not work if u upload photo -> cropped -> then upload original photo again
    e.target.value = null;
  };

  const readImageFromFile = (files) => {
    const reader = new FileReader();
    reader.onload = () => {
      // set preview image
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const onBlob = (blob) => {
    if (!blob) return;
    setFile(blob);
    readImageFromFile([blob]);
  };
  const confirmFileCrop = () => {
    const croppedCanves = cropper.getCroppedCanvas();
    // disabled because of performance issue due ui thread blocking, readImageFromFile used instead
    // setImage(croppedCanves.toDataURL('image/webp', 0.9));
    croppedCanves.toBlob(onBlob, 'image/webp', 0.9);
    showCropper(false);
    setDidSelectCroppedImage(true);
  };

  // eslint-disable-next-line no-unused-vars
  const clearImage = () => {
    setImage(null);
    setFile(null);
    onClear();
  };

  // const handleModalVisibilityChange = (willTryToOpen) => {
  // 	const willTryToClose = !willTryToOpen;
  // 	// prevent close modal if didn't confirm cropped image
  // 	if (
  // 		willTryToOpen ||
  // 		(willTryToClose && cropperIsVisible && didSelectCroppedImage)
  // 	)
  // 		showCropper(willTryToOpen);
  // };

  return (
    <div>
      {label && <label className='label'>{label}</label>}
      {/* this logic to hide input when image is selected & */}
      {(!image || (!didSelectCroppedImage && !cropperIsVisible)) && (
        <DragDropInput placeholder={placeholder} onChange={onChange} />
      )}

      {/* preview continer */}
      <div style={{ width: '100%' }}>
        {/* <input type="file" onChange={onChange} /> */}

        {
          // preview image container
          didSelectCroppedImage && image && (
            <div style={{ position: 'relative' }}>
              <img
                src={image}
                style={{ objectFit: 'contain' }}
                height={200}
                alt='الصورة المراد تحميلها'
              />
              <input
                hidden
                ref={fileInputRef}
                onChange={onChange}
                type='file'
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'absolute',
                  background: 'rgb(0, 0, 0,0.1)',
                  right: '5px',
                  top: '5px',
                }}
              >
                <IconButton
                  size='small'
                  sx={{ color: 'white' }}
                  type='button'
                  onClick={() => showCropper(true)}
                >
                  <CropIcon />
                </IconButton>
                <IconButton
                  size='small'
                  sx={{ color: 'white' }}
                  type='button'
                  onClick={() => fileInputRef.current.click()}
                >
                  <ChangeCircleIcon />
                </IconButton>
                <IconButton
                  size='small'
                  sx={{ color: 'white' }}
                  type='button'
                  onClick={clearImage}
                >
                  <ClearIcon />
                </IconButton>
              </div>
            </div>
          )
        }
      </div>

      {error && <p style={{ color: 'red' }}>{helperText}</p>}

      {/* crop modal */}
      <BasicModal
        isVisible={cropperIsVisible}
        // prevent dismiss
        setVisibility={showCropper}
      >
        <Cropper
          movable={false}
          draggable={false}
          modal={false}
          style={{
            height: 200,
            width: '100%',
          }}
          initialAspectRatio={ratio}
          aspectRatio={ratio}
          src={image}
          zoomable={false}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          // responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides
        />

        <p style={{ textAlign: 'center' }}>
          يرجي تحديد ابعاد الصورة لكي تظهر بشكل مناسب في الموقع
        </p>

        <Button
          fullWidth
          size='large'
          sx={{ mt: 2 }}
          variant='contained'
          type='button'
          onClick={confirmFileCrop}
        >
          اختيار
        </Button>
      </BasicModal>
    </div>
  );
};

export default ImageCropPicker;
