import { Typography } from "@mui/material"
import { unary } from "lodash"
import React, { useState } from "react"
import { useQuery } from "react-query"
import DateFilter from "src/components/DateFilter"
import TableWrapper from "src/components/TableWrapper"
import { dateRangeFilters } from "src/lib/dateStats"
import { getStatistics } from "src/utils/AdminApi"

export default function NewStatsPage() {
  const [selectedRange, setSelectedRange] = useState(dateRangeFilters.today)

  const { data = { orders_in_period: [], purchases_in_period: [], shipping_providers_orders: [] } } = useQuery(
    [selectedRange.key],
    () => getStatistics(selectedRange.fromDate, selectedRange.toDate),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )
  //   data.orders_in_period => [{site_name:'name',cnt:0}]
  return (
    <div>
      <DateFilter value={selectedRange} onChange={unary(setSelectedRange)} />

      <Typography my={2} variant="h4">
        طلبات شركات الشحن
      </Typography>
      <TableWrapper
        headerColumns={["الشركة", "عدد الطلبات"]}
        dataRows={data.shipping_providers_orders.map((r) => [r.shippingProvider, r.orders_count])}
      />
      <Typography my={2} variant="h4">
        طلبات المتاجر{" "}
      </Typography>
      <TableWrapper
        headerColumns={["المتجر", "عدد الطلبات", "طلبات الموقع"]}
        dataRows={data.orders_in_period.map((r) => [r.site_name, r.cnt, r.storefront_cnt])}
      />
      <Typography my={2} variant="h4">
        مشتريات المتاجر{" "}
      </Typography>
      <TableWrapper headerColumns={["الموقع", "عدد المشتريات"]} dataRows={data.purchases_in_period.map((r) => [r.site_name, r.cnt])} />
    </div>
  )
}
