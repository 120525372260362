export function normalizeArabic(str, removeNumbers = false) {
  const arabicMap = {
    أ: "ا",
    إ: "ا",
    آ: "ا",
    ة: "ه",
    ى: "ي",
    "ً": "",
    "ٌ": "",
    "ٍ": "",
    "َ": "",
    "ُ": "",
    "ِ": "",
    "ّ": "",
    "ْ": "",
    ئ: "ي",
    ؤ: "و",
    " ": " ",
  }

  let normalizedStr = str.replace(/[\u0600-\u06FF]/g, (char) => arabicMap[char] || char)
  normalizedStr = normalizedStr.replace(/[^ا-ي ]/g, "") // Remove non-Arabic characters
  if (removeNumbers) normalizedStr = normalizedStr.replace(/[\d-]/g, "") // Remove numbers and dashes

  return normalizedStr
}
