import React, { useEffect, useState } from "react";
import supabase from "src/supabase";

function FAccountsPage() {
  const [accountRecords, setAccountRecords] = useState([]);

  useEffect(() => {
    // Fetch account records from the "account" table
    async function fetchAccountRecords() {
      const { data, error, ...any } = await supabase
        .from("account")
        .select("*"); // You can specify the columns you want to fetch here
      console.log(data, error, any);

      if (error) {
        console.error("Error fetching account records:", error);
      } else {
        setAccountRecords(data);
      }
    }

    fetchAccountRecords();
  }, []);

  return (
    <div>
      <h1>Account Records</h1>
      <ul>
        {accountRecords.map((account) => (
          <li key={account.id}>{account.id}</li>
          // Replace 'columnName' with the actual column name you want to display
        ))}
      </ul>
    </div>
  );
}

export default FAccountsPage;
