import { toast } from "react-toastify"
import { createPropertyWithValues } from "src/utils/AdminApi"
import BasicModal from "./BasicModal"
import { DynamicForm } from "vetrina-ui"

export default function AddPropertyModal({ tenantId, isVisible = false, setVisibility, onCreate }) {
  const onSubmit = async ({ name, propertyValues }) => {
    try {
      // const res = await addProperty(name, values.trim.split(','));
      // TODO we need to create values one by one

      // convert '1','2',' 3' to ['1','2','3']
      const res = await createPropertyWithValues(
        name,
        propertyValues
          .trim()
          // arabic or english comma
          .split(/[,،]/)
          // ignore emptty string
          .filter((v) => {
            const trimmed = v.trim()
            if (trimmed.length > 0) return trimmed
          }),
        tenantId
      )
      onCreate(res)
    } catch (error) {
      // handle error
      console.log(error)
      toast.error("حدث خطا غير متوقع")
    }
  }
  // label , csv, button to create,
  return (
    <BasicModal title="اضافة خاصية" isVisible={isVisible} setVisibility={setVisibility}>
      <DynamicForm config={formConfig} onSubmit={onSubmit} />
    </BasicModal>
  )
}

const formConfig = {
  inputs: [
    {
      label: "الخاصية",
      placeholder: "مثال: اللون",
      name: "name",
      type: "text",
      value: "",
    },
    {
      label: "الاختيارات",
      placeholder: "مثال: احمر،اصفر،اخضر",
      name: "propertyValues",
      type: "text",
      helperText: "بامكانك اضافة اختيارات متعددة من خلال وضع فاصلة بين كل اختيار، مثال: احمر، اصفر، اخضر",
      // validation: nameValidation,
      value: "",
    },
  ],
}
