import React from "react";
import NavSection from "src/components/NavSection";
import sidebarConfig from "src/layouts/AdminDashboard/SidebarConfig";

export default function MorePage() {
  return (
    <div>
      <NavSection navConfig={sidebarConfig.filter((a) => !a.tabIndex)} />
    </div>
  );
}
