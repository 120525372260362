import { DynamicForm } from "vetrina-ui"
import { showModel } from "./showModel"

export function DynamicFormModal({
  // function to close the modal
  formConfig,
  proceed,
  cancel,
  dismiss,
}) {
  return <DynamicForm config={formConfig} onSubmit={proceed} />
}

export default function showDynamicFormModal({ formConfig, proceed, cancel, dismiss, title = "" }) {
  return showModel({
    title,
    component: <DynamicFormModal formConfig={formConfig} proceed={proceed} cancel={cancel} />,
  })
}
