import PropTypes from "prop-types"
import { useEffect } from "react"
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom"
// material
import { experimentalStyled as styled } from "@mui/material/styles"
import { IconButton, Box, Drawer, Typography, Stack } from "@mui/material"
// components
import Logo from "../../components/Logo"
import Scrollbar from "../../components/Scrollbar"
import NavSection, { NavItem } from "../../components/NavSection"
import { MHidden } from "../../components/@material-extend"
//
import sidebarConfig from "./SidebarConfig"
// redux
import preval from "preval.macro"
import useUser from "src/hooks/useUser"

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}))
// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation()
  const { logout } = useUser()

  const navigate = useNavigate()

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={{
        height: "95%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box
          component={RouterLink}
          to="/"
          sx={{
            display: "inline-flex",
            textDecoration: "none",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Logo sx={{ textAlign: "center" }} />
        </Box>
      </Box>
      <NavSection navConfig={sidebarConfig} />
      <Box sx={{ flexGrow: 1 }} />
      <Stack alignItems="center" justifyContent="center">
        {/* {new Date().toISOString} */}
        {preval`module.exports = new Date().toISOString();`}
      </Stack>
      <IconButton
        onClick={async () => {
          try {
            await logout()
            navigate("/login", { replace: true })
          } catch (err) {
            alert(err)
          }
        }}
      >
        <img src="/static/icons/logout.svg" width="26" height="22" />
        <Typography sx={{ fontWeight: "bold" }} variant="body2">
          تسجيل الخروج
        </Typography>
      </IconButton>
    </Scrollbar>
  )

  return (
    <RootStyle>
      {/* for mobile screens */}
      <MHidden width="lgUp">
        <Drawer
          anchor="left"
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      {/* for large screens */}
      <MHidden width="lgDown">
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  )
}
