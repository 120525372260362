import { Typography } from "@mui/material"
import React from "react"
import { useQuery } from "react-query"
import TenantPaymentsList from "src/components/tenant-payments/TenantPaymentsList"
import useUser from "src/hooks/useUser"
import { fetchContent } from "src/utils/AdminApi"

export default function Bills() {
  const { user, isAdmin } = useUser()
  const { data, refetch } = useQuery("payments", () => fetchContent("tenant-payment.tenant-payment?amount_gt=0&paid=false&_sort=id:desc"), {
    enabled: isAdmin,
  })
  const payments = data?.results ?? []
  console.log("payments", payments)

  if (!isAdmin) return "no access"
  return (
    <div>
      <Typography variant="h4">فواتير مش مدفوعة</Typography>
      <TenantPaymentsList showSite refetch={refetch} payments={payments} />
    </div>
  )
}
