const JaroWinklerDistance = require("./JaroWinklerDistance")

const getMatchedCity = (stirngToSearch, targetArray, targetKey, threshold) => {
  let matchedElement = null
  let maxScore = 0
  targetArray.forEach((element) => {
    const targetString = targetKey ? element[targetKey] : element
    const score = JaroWinklerDistance(stirngToSearch, targetString, {})
    if (score >= threshold && score > maxScore) {
      matchedElement = element
      maxScore = score
    }
  })
  return matchedElement
}

module.exports = {
  getMatchedCity,
}

// console.log('match', getMatch('غريان', cities, 'node', 0.8));
