import imageCompression from 'browser-image-compression';
export const preventDefault = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

// eslint-disable-next-line no-unused-vars
const formatFileSize = (size) => {
  if (size === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const validateFileType = (file) => {
  const validTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    // 'image/gif',
    // 'image/x-icon',
  ];
  if (validTypes.indexOf(file.type) === -1) {
    return false; // 'unsupported file type';
  }

  return true;
};

export const validateFileSize = (file, sizeLimitInMB) => {
  if (file.size > 1024 * 1024 * sizeLimitInMB) return false; // 'file size should be less then sizeLimitInMB';
  return true;
};

// eslint-disable-next-line no-unused-vars
const getFileType = (fileName) => {
  return (
    fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
    fileName
  );
};

export const dragDropDivProps = {
  onDragOver: preventDefault,
  onDragEnter: preventDefault,
  onDragLeave: preventDefault,
};

const options = {
  maxSizeMB: 10,
  maxWidthOrHeight: 800,
  useWebWorker: true,
};

export const getCompressedFile = async (file) => {
  // console.log(`file size ${file.size / 1024 / 1024} MB`); // smaller than maxSizeMB
  const compressedFile = await imageCompression(file, options);
  // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
  // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
  return compressedFile;
};

export const readImageFromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
};
