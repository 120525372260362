import { Alert, Container } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { DynamicForm } from "vetrina-ui"
import { createTestingAccount } from "src/utils/AdminApi"
import FileUploadField from "src/components/ImageCropPicker/FileUploadField"
import useUser from "src/hooks/useUser"

export default function CreateTenantPage() {
  const nav = useNavigate()
  const { user, isAdmin } = useUser()

  const [error, seterror] = React.useState()
  const onSubmit = async (values, actions) => {
    const { primary_host, username, host, site_name, duration, logo } = values

    try {
      const res = await createTestingAccount(username, host, site_name, duration ?? 14, logo, primary_host)

      toast.success("تم انشاء المتجر بنجاح")
      const copied = `
      رابط موقع الادارة :
      https://accounts.vetrinas.ly/

      اسم المستخدم  
      ${username}@vetrinas.ly    

      الرمز السري
      12345678      
      `
      try {
        navigator.clipboard.writeText(copied)
      } catch (error) {
        toast.error("copy fail")
      }
      toast.success("تم اضافة المتجر بنجاح ونسخ معلومات المتجر")
      actions.resetForm()
      return nav(`/tenants/${res.data.tenant.id}`)
    } catch (err) {
      console.log("CreateTenantPage:onSubmit: ", err)
      seterror(err)
    }
  }

  const formConfig = {
    inputs: [
      {
        label: "اسم المستخدم",
        placeholder: "اسم المستخدم",
        name: "username",
        type: "text",
        // validation: nameValidation,
        value: "",
      },
      // {
      //   label: 'كلمة المرور',
      //   placeholder: 'كلمة المرور',
      //   name: 'password',
      //   type: 'text',
      //   // validation: nameValidation,
      //   value: '',
      // },
      {
        label: "شعار الموقع",
        name: "logo",
        type: "image-file",
        ratio: 1,
        component: ({ formik, input }) => {
          return (
            <FileUploadField
              ratio={input.ratio}
              label={input.label}
              // {...getInputProps(formik)(input.name)}
              value={formik.values[input.name]}
              onChange={(newValue) => {
                console.log("newValue", newValue)
                console.log("input.name", input.name)
                formik.setFieldValue(input.name, newValue)
              }}
            />
          )
        },
        // validation: nameValidation,
        value: "",
      },
      {
        label: "hostname",
        placeholder: "hostname *",
        name: "host",
        type: "text",
        value: "",
      },
      // {
      //   label: 'custom domain name',
      //   placeholder: 'custom domain name',
      //   name: 'primary_host',
      //   type: 'text',
      //   value: '',
      // },

      {
        label: "اسم الموقع",
        placeholder: "اسم الموقع",
        name: "site_name",
        type: "text",
        value: "",
      },
    ],
  }

  if (isAdmin)
    formConfig.inputs.push({
      label: "المدة",
      placeholder: "المدة",
      name: "duration",
      type: "number",
      value: "14",
    })
  return (
    <Container>
      <DynamicForm config={formConfig} onSubmit={onSubmit} />
      {error && (
        <Alert severity="error">
          <p>{JSON.stringify(error)}</p>
        </Alert>
      )}
    </Container>
  )
}
