import { toast } from "react-toastify"
import { getAdminToken, removeAdminToken } from "./storage"
import { baseURL } from "src/config"
const axios = require("axios")

/**
 *
 * @param {*} url
 * @param {*} method
 * @param {*} body
 * @param {*} token
 * @param {*} files ex : {main_image:file1,gallery[0]:file2}
 * @returns
 */
export const makeHttpRequest = async (url, method = "GET", body, token, files) => {
  let headers

  if (token) {
    headers = { Authorization: `Bearer ${token}` }
  } else {
    token = getAdminToken()
    if (token?.length > 0) headers = { Authorization: `Bearer ${token}` }
  }

  let data
  if (files) {
    // Create an object of formData
    data = new FormData()
    data.append("data", JSON.stringify(body))

    for (let key in files) {
      // handle the case of pass single file and array of files
      // ex : image -> single file , galleryImages array of files
      const file = files[key]
      if (Array.isArray(file)) {
        file.forEach((f, i) => data.append(`files.${key}`, f))
      } else {
        data.append(`files.${key}`, file)
      }
    }
    // data.append('files.main_image',files);
    // Update the formData object
  } else {
    data = body
  }

  return new Promise((resolve, reject) => {
    axios
      .request({
        method,
        baseURL,
        url,
        data,
        headers,
      })
      .then((response) => resolve(response.data))
      .catch((err) => reject(handleError(err)))
  })
}

const handleError = (err) => {
  if (err.response) {
    // Request made and server responded
    // console.log("catch Request made and server responded");
    // console.log(err.response.data);
    // console.log(err.response.status);
    // console.log(err.response.headers);

    // return err.response.data // that how you get json response
    if (err.response.status === 401 || err.response.status === 403) {
      return toast.error("ليس لديك صلاحية للوصول لهذه البيانات")
      // removeAdminToken();
      // return (window.location.href = '/login');
    } else if (err.response.status === 429) {
      return {
        message: "لقد قمت بعدد كبير من المحاولات، يرجي المحاولة في وقت لاحق",
      }
    }
    console.log("server error msg : ", err.response.data)
    return err.response.data

    // return 'Request made and server responded'
  } else if (err.request) {
    // The request was made but no response was received
    // console.log("catch The request was made but no response was received");
    // console.log(err.code);
    // code ECONNREFUSED errno -61
    // console.log(err.request);
    if (err.code === "ECONNREFUSED") {
      return "no connection"
    }
    return err.message
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log('catch Something happened in setting up the request that triggered an Error');
    // console.log('Error', err.message);
    return err.message
  }
}

export const uploadFileRequest = (file) => {
  const data = new FormData()
  data.append("files", file)
  const token = getAdminToken()
  const headers = { Authorization: `Bearer ${token}` }

  return new Promise((resolve, reject) => {
    axios
      .post(`${baseURL}/upload`, data, {
        headers,
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          console.log(percentCompleted)
        },
      })
      .then((response) => resolve(response.data))
      .catch((err) => reject(handleError(err)))
  })
}
