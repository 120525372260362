import { makeHttpRequest } from "./requestBuilder";

const adminEndpoints = {
  login: "/admin/login",
  users: "/admin/users",
  me: "/admin/users/me",
};

// CRUD Admins

// #### admin routes

export const adminLogin = (email, password) =>
  makeHttpRequest(adminEndpoints.login, "POST", { email, password });
export const getAdminUserInfo = (token) =>
  makeHttpRequest(adminEndpoints.me, "GET", null, token);

export const updateAdminUserInfo = (user) =>
  makeHttpRequest(adminEndpoints.me, "PUT", user);

const admins_endpoint = "/admin/users";
export const getAdmins = () => makeHttpRequest(admins_endpoint, "GET");
export const deleteAdmin = (id) =>
  makeHttpRequest(admins_endpoint + `/${id}`, "DELETE");
export const deleteAdmins = (ids) =>
  makeHttpRequest(admins_endpoint + "/batch-delete", "POST", ids);
export const addAdmin = (newAdmin, role = 1) =>
  makeHttpRequest(admins_endpoint, "POST", { ...newAdmin, roles: [role] });
export const updateAdmin = (id, updatedAdmin) =>
  makeHttpRequest(admins_endpoint + `/${id}`, "PUT", {
    ...updatedAdmin,
    isActive: true,
  });

// sales
// https://api.vetrinas.ly/content-manager/collection-types/application::guest-orders.guest-orders?where[0][TenantID.host_contains]=enso
export const getSales = () => makeHttpRequest("/sales");
export const broadcastNotification = (notification) =>
  makeHttpRequest("/notifications/broadcast", "POST", notification);
// export const getSales = () => makeHttpRequest('//content-manager/collection-types/application::tenant.tenant?page=1&pageSize=10&_sort=id:DESC');
export const fetchContent = (route) =>
  makeHttpRequest(`/content-manager/collection-types/application::${route}`);
////content-manager/collection-types/application::tenant.tenant/453
export const deleteContent = (route, id) =>
  makeHttpRequest(
    `/content-manager/collection-types/application::${route}/${id}`,
    "DELETE"
  );
export const updateContent = (route, id, body, files) =>
  makeHttpRequest(
    `/content-manager/collection-types/application::${route}/${id}`,
    "PUT",
    body,
    null,
    files
  );

export const createPropertyWithValues = (name, values, TenantID) =>
  makeHttpRequest("/propertiesWithValues", "POST", {
    name,
    values,
    TenantID,
  });

export const renewSubscription = (
  duration,
  price,
  bundleValue,
  TenantID,
  paid,
  received_by
) =>
  makeHttpRequest("/renewSubscription", "POST", {
    duration,
    paid,
    bundle: {
      price,
      value: bundleValue,
    },
    TenantID,
    received_by,
  });
export const clearStock = (TenantID, should_delete_purchases) =>
  makeHttpRequest("/clearStock", "POST", {
    TenantID,
    should_delete_purchases,
  });

export const createTestingAccount = (
  username,
  host,
  site_name,
  duration,
  logo,
  primary_host
) =>
  makeHttpRequest("/createTestingAccount", "POST", {
    username,
    host,
    site_name,
    duration,
    discription: site_name,
    primary_host,
    logo,
  });

export const addProduct = (product, mainImage, images) =>
  makeHttpRequest("/products", "POST", product, null, {
    main_image: mainImage,
    images,
  });

export const getStatistics = (fromDate, toDate) =>
  makeHttpRequest(`/vetrina-admin/stats?fromDate=${fromDate}&toDate=${toDate}`);

export const getLoginStats = () =>
  makeHttpRequest(`/vetrina-admin/daily-login`);

export const updateUserPassword = (password, uid) =>
  makeHttpRequest("/user/reset-password", "PUT", { password, uid });

/*  cache */
export const getAllCacheKeys = () => makeHttpRequest("/vetrina-admin/cache");
export const deleteKey = (key) =>
  makeHttpRequest("/vetrina-admin/cache/" + key, "DELETE");
export const clearKeys = () =>
  makeHttpRequest("/vetrina-admin/cache", "DELETE");

export const addTenantAdmin = ({
  name,
  email,
  password = "12345678",
  tenantId,
}) =>
  makeHttpRequest("/users", "POST", {
    name,
    email,
    password,
    username: email,
    role: 3, //tenant role
    provider: "local",
    OwnedWebsite: tenantId,
  });
