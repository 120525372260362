import { lazy, useEffect, useState } from "react"
import { Navigate, useRoutes } from "react-router-dom"

// layouts
import AdminDashboardLayout from "./layouts/AdminDashboard"

import LogoOnlyLayout from "./layouts/LogoOnlyLayout"

//
import NotFound from "./pages/Page404"

//redux
import { CircularProgress, Stack } from "@mui/material"

import AdminAccount from "./pages/AdminAccount"
import AdminLogin from "./pages/AdminLogin"
import Admins from "./pages/Admins"
import HomePage from "./pages/HomePage"
import TenantsPage from "./pages/TenantsPage"
import CreateTenantPage from "./pages/CreateTenantPage"
import TenantDetailsPage from "./pages/TenantDetailsPage/TenantDetailsPage"
import StatsPage from "./pages/Stats/StatsPage"
import TenantProducts from "./pages/TenantProducts"
import NewPage from "./pages/NewPage"
import NewStatsPage from "./pages/Stats/NewStatsPage"
import NotificationsPage from "./pages/NotificationsPage"
import CachePages from "./pages/CachePages"
import InActiveTenantsPage from "./pages/InActiveTenantsPage"
import FAccountsPage from "./pages/finance/FAccountsPage"
import Bills from "./pages/Bills"
import MorePage from "./pages/MorePage"
import ProviderMapping from "./pages/ProviderMapping"
import LinksPage from "./pages/LinksPage"
import MonitorPage from "./pages/MonitorPage"
import Settings from "./pages/settings"
import useUser from "./hooks/useUser"
import DeliveryFormTest from "./pages/DeliveryFormTest"
import Dhl from "./pages/Dhl"
import AnyProviderMapping from "./pages/AnyProviderMapping/AnyProviderMapping"
const Builder = lazy(() => import("src/pages/Builder"))

// ----------------------------------------------------------------------

export default function Router({ ...props }) {
  let { user: admin, isLoading } = useUser()

  const loadingView = (
    <Stack spacing={4} minHeight="100vh" justifyContent="center" alignItems="center">
      <CircularProgress />
      loading
    </Stack>
  )

  const routes = useRoutes([
    {
      path: "/",
      element: admin ? <AdminDashboardLayout /> : <Navigate to="/login" />,

      children: [
        {
          path: "/",
          element: <HomePage user={admin} />,
        },
        {
          path: "/tenants",
          element: <TenantsPage user={admin} />,
        },
        {
          path: "/inactive-tenants",
          element: <InActiveTenantsPage user={admin} />,
        },
        {
          path: "/tenants/:id",
          element: <TenantDetailsPage user={admin} />,
        },
        {
          path: "/tenants/:id/products",
          element: <TenantProducts user={admin} />,
        },
        {
          path: "/tenants/create",
          element: <CreateTenantPage user={admin} />,
        },
        {
          path: "stats",
          element: <StatsPage user={admin} />,
        },
        {
          path: "statistics",
          element: <NewStatsPage user={admin} />,
        },
        {
          path: "bills",
          element: <Bills user={admin} />,
        },
        {
          path: "more",
          element: <MorePage user={admin} />,
        },
        {
          path: "users",
          element: <Admins user={admin} />,
        },
        {
          path: "me",
          element: <AdminAccount user={admin} />,
        },
        {
          path: "new",
          element: <NewPage user={admin} />,
        },
        {
          path: "notifications",
          element: <NotificationsPage user={admin} />,
        },
        {
          path: "cache",
          element: <CachePages user={admin} />,
        },
        {
          path: "/finance",
          element: <FAccountsPage user={admin} />,
        },
        {
          path: "/monitor",
          element: <MonitorPage user={admin} />,
        },
        {
          path: "/provider-mapping",
          element: <ProviderMapping />,
        },
        {
          path: "/shipping/:provider/mapping",
          element: <AnyProviderMapping />,
        },
        {
          path: "/links",
          element: <LinksPage />,
        },
        {
          path: "/settings",
          element: <Settings />,
        },
        {
          path: "/delivery-form-test",
          element: <DeliveryFormTest />,
        },
        {
          path: "/dhl",
          element: <Dhl />,
        },
        {
          path: "/builder",
          element: <Builder />,
        },
      ],
    },
    {
      path: "/",
      element: !admin ? <LogoOnlyLayout /> : <Navigate to="/" />,

      children: [
        {
          path: "login",
          element: <AdminLogin />,
        },
      ],
    },
    {
      path: "404",
      element: <NotFound />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ])

  if (isLoading) {
    return loadingView
  }

  return routes
}
