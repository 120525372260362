// material
import { Container, Typography } from "@mui/material"
// components
import GeneralStatsSection from "src/components/GeneralStatsSection"
import Page from "../components/Page"
import useUser from "src/hooks/useUser"
export default function HomePage() {
  const { user, isAdmin } = useUser()

  return (
    <Page title="Home">
      <Container>
        <Typography variant="h4">{`اهلا ${user.firstname} ${user.lastname}`}</Typography>
        {isAdmin && <GeneralStatsSection />}
      </Container>
    </Page>
  )
}
