import React, { useState } from "react"
import { DeliveryForm } from "../DeliveryFormTest"
import { Button } from "@mui/material"
import { makeHttpRequest } from "src/utils/requestBuilder"
import { toast } from "react-toastify"

export default function TestCreateShipment({ provider }) {
  const [values, setValues] = useState({
    country: "LY",
    city: "طرابلس",
    district: "جنزور",
    total_weight: "0.5",
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <div>
      <DeliveryForm values={values} setValues={setValues} />
      <Button
        disabled={isSubmitting}
        variant="contained"
        fullWidth
        sx={{ mt: 3 }}
        onClick={async () => {
          setIsSubmitting(true)
          try {
            const res = await makeHttpRequest("/createTestShippment", "post", {
              provider: provider,
              order: {
                id: 4178000,
                address: "المرج",
                uuid: "ebccd4f0-8e72-4299-ab4a-4f2b0f59787a",
                payment: {
                  gateway: "cash",
                },
                delivery_info: {
                  type: "delivery",
                  city: values.city,
                  district: values.district,
                },
                phone_country_code: "+218",
                discount: 0,

                items: [
                  {
                    id: 71930,
                    quantity: 1,
                    price: 100,
                    product: {
                      id: 393,
                      name: "حذاء رجالي مميز",
                      price: 100,
                      quantity: 99999,
                    },
                  },
                ],
                oldSubTotal: 100,
                oldTotal: 100,
                subTotal: 100,
                total: 100,
                name: "ahmed almusrati",
                email: "",
                phone: "918345734",
                customer: {
                  name: "ahmed almusrati",
                  email: "",
                  phone: "918345734",
                },
              },
              extraValues: {},
            })

            console.log(res)
            alert(
              `create_result: ${res.create_result.success}\ndelete_response: ${res.delete_response.success}\ndetails_response: ${res.details_response.success}\n`
            )
          } catch (err) {
            toast.error(err?.response?.data ?? err?.message ?? "something went wrong")
          } finally {
            setIsSubmitting(false)
          }
        }}
      >
        Send
      </Button>
    </div>
  )
}
