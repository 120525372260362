import { Stack } from '@mui/material';
import { useState } from 'react';
import ToggleButtons from './ToggleButtons';
import { dateRangeFilters } from 'src/lib/dateStats';

const toggleButtons = Object.values(dateRangeFilters).map((date) => ({
  label: date.label,
  value: date.key,
}));

export default function DateFilter({ value, onChange }) {
  return (
    <Stack
      mb={2}
      spacing={1}
      alignItems='center'
      direction='row'
      justifyContent='space-between'
    >
      <ToggleButtons
        onChange={(e) => onChange(dateRangeFilters[e.target.value])}
        value={value.key}
        buttons={toggleButtons}
      />
    </Stack>
  );
}
