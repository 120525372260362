import { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
// material
import { Button, Card, Container, Stack, Typography } from "@mui/material"
import Page from "src/components/Page"

export default function LinksPage({}) {
  const navigate = useNavigate()
  const [state, setState] = useState()
  useEffect(() => {}, [])

  return (
    <Page title="فترينا">
      <Container maxWidth="md">
        <Card sx={{ p: 3 }}>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">LinksPage </Typography>
            </Stack>

            <Link to={"/provider-mapping"}>
              <Typography variant="h4">اعدادات الربط مع skyex (old) </Typography>
            </Link>
            <Link to={"/delivery-form-test"}>
              <Typography variant="h4">اختبار طرق التوصيل </Typography>
            </Link>
            <Link to={"/dhl"}>
              <Typography variant="h4">dhl </Typography>
            </Link>

            {["skyex", "nawris", "vanex", "darb", "foxline"].map((item, index) => {
              return (
                <Link to={`/shipping/${item}/mapping`}>
                  <Typography variant="h4">اعدادات الربط مع {item} </Typography>
                </Link>
              )
            })}
          </Stack>
        </Card>
      </Container>
    </Page>
  )
}
