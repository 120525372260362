import * as Yup from "yup"
import { useState } from "react"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { useFormik, Form, FormikProvider } from "formik"
import { Icon } from "@iconify/react"
import eyeFill from "@iconify/icons-eva/eye-fill"
import eyeOffFill from "@iconify/icons-eva/eye-off-fill"
// material
import { Link, Button, Stack, Checkbox, Typography, TextField, IconButton, InputAdornment, CircularProgress, FormControlLabel } from "@mui/material"

// redux
import useUser from "src/hooks/useUser"

// ----------------------------------------------------------------------

export default function AdminLoginForm() {
  const { login } = useUser()

  const navigate = useNavigate()
  const [error, setError] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("ادخل بريد الكتروني صحيح").required("يحب ادخال البريد الالكتروني"),
    password: Yup.string().required("ادخل كلمة المرور"),
  })

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (v) => {
      setError("")
      try {
        await login(v.email, v.password)
        navigate("/", { replace: true })
      } catch (err) {
        setError(err.message)
      }
    },
  })

  const { errors, touched, values, isSubmitting, setSubmitting, handleSubmit, getFieldProps } = formik

  const handleShowPassword = () => {
    setShowPassword((show) => !show)
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="البريد الالكتروني"
            type="email"
            label="البريد الالكتروني"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="كلمة المرور"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        {/*
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
  <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="تذكرني"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack> */}

        {isSubmitting ? (
          <CircularProgress color="secondary" sx={{ mt: 4 }} />
        ) : (
          <Button
            sx={{ mt: 4 }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            // onClick={()=>setSubmitting(true)}
            // loading={isSubmitting}
          >
            تسجيل الدخول
          </Button>
        )}

        {error && (
          <Typography color="red" pt={2}>
            {error}
          </Typography>
        )}
      </Form>
    </FormikProvider>
  )
}
