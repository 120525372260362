import { getDaysDifference } from 'src/utils/date';

export const getActiveSubscription = (t) => {
  return t?.payments[t.payments.length - 1];
};

export const storeIsExpired = (store) => {
  return isExpired(getActiveSubscription(store)?.expiration_date);
};

export const isExpired = (dateString) => {
  try {
    const dateObj = new Date(dateString);
    if (dateObj.getTime() < Date.now()) return true;
    else return false;
  } catch (error) {
    return false;
  }
};

export const getSubscripitonStatusString = (t) => {
  const dateString = getActiveSubscription(t)?.expiration_date;
  if (!dateString) return 'forever';
  const diff = getDaysDifference(dateString);
  if (diff > 0) return `مزال ${diff} يوم`;
  return `منتهي منذ ${diff * -1} يوم`;
};
