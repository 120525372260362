import React, { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./dropzone.css";

const Dropzone = ({
  helperText,
  error,
  images,
  onImagesChanged,
  label,
  selectedFiles = [],
  onFilesChanged,
  limit = 10,
  allowMultiple = false,
  ...props
}) => {
  const fileInputRef = useRef(null);
  const modalImageRef = useRef();
  const modalRef = useRef();
  const progressRef = useRef();
  const uploadRef = useRef();
  const uploadModalRef = useRef();

  const preventDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = (e) => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  // const fileInputClicked = (e) => {
  //     // filesSelected();
  //     fileInputRef.current.click();
  //     // preventDefault(e);

  // }

  const onClick = (e) => {
    preventDefault(e);
  };

  const handleFiles = (files) => {
    console.log("handleFiles", files);
    let isThereError = null;

    if (!allowMultiple) {
      isThereError = validateFile(files[0]);
      if (!isThereError) {
        onFilesChanged([files[0]]);
      } else {
        toast.error(isThereError);
      }
      // setSelectedFiles([files[0]])
      return;
    }

    const _files = [...selectedFiles];
    for (let i = 0; i < files.length; i++) {
      _files.push(files[i]);

      /*   if (_files.length === limit) {
        toast.error(`you can't upload more than ${limit} images`);
        break;
      }
      isThereError = validateFile(files[i]);
      if (!isThereError) {
        _files.push(files[i]);
      } else {
        toast.error(isThereError);
      } */
    }
    onFilesChanged(_files);
  };

  const validateFile = (file) => {
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/x-icon",
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return "unsupported file type";
      return false;
    }
    if (file.size >= 1024 * 1024 * 2.5)
      return "file size should be less then 2.5MB";

    // return false;

    return null;
  };

  const fileSize = (size) => {
    if (size === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const fileType = (fileName) => {
    return (
      fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
      fileName
    );
  };

  const removeFile = (name) => {
    const index2 = selectedFiles.findIndex((e) => e.name === name);
    selectedFiles.splice(index2, 1);
    onFilesChanged([...selectedFiles]);
    // setSelectedFiles([...selectedFiles]);
  };

  const openImageModal = (file) => {
    const reader = new FileReader();
    modalRef.current.style.display = "block";
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      modalImageRef.current.style.backgroundImage = `url(${e.target.result})`;
    };
  };

  const closeModal = () => {
    modalRef.current.style.display = "none";
    modalImageRef.current.style.backgroundImage = "none";
  };

  const closeUploadModal = () => {
    uploadModalRef.current.style.display = "none";
  };

  return (
    <div>
      {label && <lable className="label">{label}</lable>}
      {images?.length > 0 && (
        <div className="grid">
          {images.map((image) => (
            <div className="preview-container">
              <img width="120" height="120" src={image.url} />
              <button
                className="remove-btn"
                onClick={(e) => {
                  e.preventDefault();
                  onImagesChanged(images.filter((i) => i.id !== image.id));
                }}
              >
                x
              </button>
            </div>
          ))}
        </div>
      )}
      {/* show upload widget if multiple images allowed is not preview mode*/}
      {(allowMultiple || !images) && (
        <div className="container">
          {/* {image && <img href={image}/> } */}
          <div
            className="drop-container"
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
            // onClick={fileInputClicked}
          >
            <div className="drop-message">
              {/* <div className="upload-icon"></div> */}
              <h6> قم بسحب وإسقاط ملفات الصور هنا لرفعها</h6>
              <h6>او</h6>
              <label className="file-upload-btn">
                <input
                  ref={fileInputRef}
                  className="file-input"
                  type="file"
                  multiple={allowMultiple}
                  onChange={filesSelected}
                />
                تصفح الملفات
              </label>
              <h6 style={{ fontSize: 12 }}>
                حجم الصورة يجب ان لايتجاوز 2.5 ميجابايت
              </h6>
            </div>
          </div>

          {error && <p style={{ color: "red" }}>{helperText}</p>}

          <div className="file-display-container">
            {selectedFiles.map((data, i) => (
              <div className="file-status-bar" key={i}>
                <div>
                  {/* <div className="file-type-logo"></div> */}
                  {/* <div className="file-type">{fileType(data.name)}</div> */}
                  <img
                    mimetype="image/png"
                    srconClick={
                      !data.invalid
                        ? () => openImageModal(data)
                        : () => removeFile(data.name)
                    }
                    src={URL.createObjectURL(data)}
                    width="55"
                    height="55"
                  />
                  <span
                    className={`file-name ${data.invalid ? "file-error" : ""}`}
                  >
                    {data.name}
                  </span>
                  <span className="file-size">({fileSize(data.size)})</span>{" "}
                  {/* {data.invalid && (
                    <span className="file-error-message">({errorMessage})</span>
                  )} */}
                  <div
                    className="file-remove"
                    onClick={() => removeFile(data.name)}
                  >
                    X
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div onClick={closeModal} className="modal" ref={modalRef}>
            <div className="overlay"></div>
            <span className="close" onClick={() => closeModal()}>
              X
            </span>
            <div className="modal-image" ref={modalImageRef}></div>
          </div>

          <div className="upload-modal" ref={uploadModalRef}>
            <div className="overlay"></div>
            <div className="close" onClick={() => closeUploadModal()}>
              X
            </div>
            <div className="progress-container">
              <span ref={uploadRef}></span>
              <div className="progress">
                <div className="progress-bar" ref={progressRef}></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropzone;
