// material
import { Stack, Button, Container, Typography, TextField } from "@mui/material"
// components
import Page from "../components/Page"
import FormikWrapper from "src/components/FormikWrapper"

import * as Yup from "yup"
import SubmitButton from "src/components/SubmitButton"
import { toast } from "react-toastify"
import useUser from "src/hooks/useUser"

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

const schema = Yup.object().shape({
  firstname: Yup.string().min(2).required("name is required"),
  lastname: Yup.string().min(2).required("name is required"),
  email: Yup.string().email("enter valid email").required("email is required"),
})

export default function AdminAccount({}) {
  const { user, updateUser } = useUser()
  const handleSubmit = async (values, actions) => {
    toast.promise(updateUser(values), {
      error: "something went wrong",
      success: "updated successfully",
      pending: "updating",
    })
  }

  return (
    <Page title="User ">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            حسابي
          </Typography>
        </Stack>

        <FormikWrapper
          hideSubmitButton
          values={{
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
          }}
          schema={schema}
          handleSubmit={handleSubmit}
        >
          {({ isSubmitting, getInputProps }) => (
            <Stack spacing={4}>
              <TextField type="text" label="الاسم" fullWidth {...getInputProps("firstname")} />
              <TextField type="text" label="اللقب" fullWidth {...getInputProps("lastname")} />

              <TextField type="email" label="البريد الالكتروني" fullWidth {...getInputProps("email")} />

              <SubmitButton isSubmitting={isSubmitting}>تحديث المعلومات</SubmitButton>
            </Stack>
          )}
        </FormikWrapper>
      </Container>
    </Page>
  )
}
