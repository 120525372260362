import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Table,
  Typography,
  TableBody,
} from '@mui/material';

// rows are array of array
export default function TableWrapper({
  align = 'center',
  dataRows,
  headerColumns = [],
  spans = [],
  colWidth = [],
}) {
  return (
    // <div>
    <div className='show-scrollbar'>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headerColumns.map((column, index) => (
                  <TableCell
                    style={{ minWidth: colWidth[index] }}
                    key={index}
                    colSpan={spans[index]}
                    align={align}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataRows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((column, index) => (
                    <TableCell
                      key={index}
                      style={{ minWidth: colWidth[index] }}
                      colSpan={spans[index]}
                      align={align}
                    >
                      <Typography>{column}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
                // <StyledTableCell align="center">{col1}</StyledTableCell>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
