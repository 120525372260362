import React from "react";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import sidebarConfig from "src/layouts/AdminDashboard/SidebarConfig";

function BottomTab({ sideBarSetState }) {
  const [value, setValue] = React.useState(0);

  function toggleSideBar() {
    sideBarSetState((prevState) => !prevState);
  }

  return (
    <AppBar
      position="fixed"
      // color="transparent"
      sx={{
        top: "auto",
        bottom: 0,
        backgroundColor: "white",
        pb: 1,
        display: { xs: "inline-block", lg: "none" },
      }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{ width: "100%", justifyContent: "space-between" }}
      >
        {sidebarConfig
          .filter((t) => t.tabIndex != null)
          .map((tab, i) => {
            return (
              <BottomNavigationAction
                key={i}
                label={tab.title}
                icon={tab.icon}
                component={RouterLink}
                to={tab.path}
              />
            );
          })}

        <BottomNavigationAction
          label="المزيد"
          icon={
            <img
              alt="menu tab icon"
              src="/static/icons/more.svg"
              width={22}
              height={22}
            />
          }
          component={RouterLink}
          to="/more"
        />
      </BottomNavigation>
    </AppBar>
  );
}

export default BottomTab;

/*
<BottomNavigation showLabels value={value}
																																																																																										onChange={(event, newValue) => {
																																																																																											setValue(newValue);
																																																																																										}} sx={{width: '100%'}}>
			<BottomNavigationAction label='الرئيسة' icon={<img src='/static/icons/sidebar/home.png' width={22} height={22} />} />
			<BottomNavigationAction label='منتجاتي'
																											icon={<img src='/static/icons/sidebar/inventory.png' width={22} height={22} />} />
			<BottomNavigationAction label='حسابي' icon={<img src='/static/icons/account.svg' width={22} height={22} />} />
			<BottomNavigationAction label='الطليات' icon={<img src='/static/icons/sidebar/box.png' width={22} height={22} />} />
			<BottomNavigationAction label='المزيد' icon={<img src='/static/icons/more.svg' width={22} height={22} />} />
		</BottomNavigation>

 */

/*
			<Card sx={{position: 'fixed', bottom: 0, width: '100%', top: 'auto'}}>
				<Grid container justifyContent='center'>
					<Grid item xs={2}>
						<Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
							<img src='/static/icons/sidebar/home.png' width={22} height={22} />
							<Typography variant='caption'>الرئيسة</Typography>
						</Box>
					</Grid>
					<Grid item xs={2}>
						<Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
							<img src='/static/icons/sidebar/inventory.png' width={22} height={22} />
							<Typography variant='caption'>منتجاتي</Typography>
						</Box>
					</Grid>
					<Grid item xs={2}>
						<Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
							<img src='/static/icons/account.svg' width={22} height={22} />
							<Typography variant='caption'>حسابي</Typography>
						</Box>
					</Grid>
					<Grid item xs={2}>
						<Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
							<img src='/static/icons/sidebar/box.png' width={22} height={22} />
							<Typography variant='caption'>الطليات</Typography>
						</Box>
					</Grid>
					<Grid item xs={2}>
						<Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
							<img src='/static/icons/more.svg' width={22} height={22} />
							<Typography variant='caption'>المزيد</Typography>
						</Box>
					</Grid>
				</Grid>
			</Card>

 */
