import * as React from 'react';
// mui components
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  IconButton,
} from '@mui/material';
// mui icons
import DeleteIcon from '@mui/icons-material/Delete';

export default function DeleteAlertDialog({ sx,onDelete }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    onDelete();
    setOpen(false);
  };

  return (
    <>
      <IconButton sx={sx} color="error" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
      fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'هل انت متاكد؟'}</DialogTitle>
        <DialogContent >
          <DialogContentText id="alert-dialog-description">
           يرجي تاكيد عملية الحدف 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>تراجع</Button >
          <Button sx={{color:'grey'}} onClick={handleDelete}>
            حدف
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
