import { Button, Container, Stack } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Dropzone from 'src/components/dropzone';
import { getCompressedFile } from 'src/components/ImageCropPicker/filesUtils';
import { addProduct } from 'src/utils/AdminApi';

export default function TenantProducts() {
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const [count, setCount] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);

  const add = async () => {
    setIsDownloading(true);
    for (const file of files) {
      try {
        const cfile = await getCompressedFile(file);
        await addProduct(
          { name: 'testing product', price: 100, quantity: 100, TenantID: id },
          cfile
        );
        setFiles((files) => files.slice(1));
        setCount((count) => count + 1);
      } catch (error) {}
    }
    toast.success('download finish');
    setCount((count) => 0);
    setIsDownloading(false);

    //     fetch("https://api.vetrinas.ly/upload", {
    //   "headers": {
    //     "accept": "*/*",
    //     "accept-language": "en-US,en;q=0.9,ar;q=0.8,tr;q=0.7",
    //     "authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjcxNjM5Mjc3LCJleHAiOjE2NzQyMzEyNzd9.njgAjSwgwSB7I1XTOI_b7TxgthzfmAejJK2V54yauW0",
    //     "cache-control": "no-cache",
    //     "content-type": "multipart/form-data; boundary=----WebKitFormBoundary4vIlzGUg1Goqtx99",
    //     "pragma": "no-cache",
    //     "sec-ch-ua": "\"Not?A_Brand\";v=\"8\", \"Chromium\";v=\"108\", \"Google Chrome\";v=\"108\"",
    //     "sec-ch-ua-mobile": "?0",
    //     "sec-ch-ua-platform": "\"macOS\"",
    //     "sec-fetch-dest": "empty",
    //     "sec-fetch-mode": "cors",
    //     "sec-fetch-site": "same-origin"
    //   },
    //   "referrer": "https://api.vetrinas.ly/admin/plugins/content-manager/collectionType/application::products.products/create",
    //   "referrerPolicy": "strict-origin-when-cross-origin",
    //   "body": "------WebKitFormBoundary4vIlzGUg1Goqtx99\r\nContent-Disposition: form-data; name=\"files\"; filename=\"blob_f96d887585.png\"\r\nContent-Type: image/png\r\n\r\n\r\n------WebKitFormBoundary4vIlzGUg1Goqtx99\r\nContent-Disposition: form-data; name=\"fileInfo\"\r\n\r\n{\"alternativeText\":\"\",\"caption\":\"\",\"name\":null}\r\n------WebKitFormBoundary4vIlzGUg1Goqtx99--\r\n",
    //   "method": "POST",
    //   "mode": "cors",
    //   "credentials": "include"
    // });
    /**
    [
  {
    "id": 11193,
    "name": "blob_f96d887585.png",
    "alternativeText": "",
    "caption": "",
    "width": 1224,
    "height": 1531,
    "formats": {
      "thumbnail": {
        "name": "thumbnail_blob_f96d887585.png",
        "hash": "thumbnail_blob_f96d887585_40edbd5105",
        "ext": ".png",
        "mime": "image/png",
        "width": 125,
        "height": 156,
        "size": 38.37,
        "path": null,
        "url": "https://vetrina-ly.s3.eu-central-1.amazonaws.com/thumbnail_blob_f96d887585_40edbd5105.png"
      },
      "large": {
        "name": "large_blob_f96d887585.png",
        "hash": "large_blob_f96d887585_40edbd5105",
        "ext": ".png",
        "mime": "image/png",
        "width": 799,
        "height": 1000,
        "size": 1273.9,
        "path": null,
        "url": "https://vetrina-ly.s3.eu-central-1.amazonaws.com/large_blob_f96d887585_40edbd5105.png"
      },
      "medium": {
        "name": "medium_blob_f96d887585.png",
        "hash": "medium_blob_f96d887585_40edbd5105",
        "ext": ".png",
        "mime": "image/png",
        "width": 600,
        "height": 750,
        "size": 730.7,
        "path": null,
        "url": "https://vetrina-ly.s3.eu-central-1.amazonaws.com/medium_blob_f96d887585_40edbd5105.png"
      },
      "small": {
        "name": "small_blob_f96d887585.png",
        "hash": "small_blob_f96d887585_40edbd5105",
        "ext": ".png",
        "mime": "image/png",
        "width": 400,
        "height": 500,
        "size": 334.66,
        "path": null,
        "url": "https://vetrina-ly.s3.eu-central-1.amazonaws.com/small_blob_f96d887585_40edbd5105.png"
      }
    },
    "hash": "blob_f96d887585_40edbd5105",
    "ext": ".png",
    "mime": "image/png",
    "size": 2371.43,
    "url": "https://vetrina-ly.s3.eu-central-1.amazonaws.com/blob_f96d887585_40edbd5105.png",
    "previewUrl": null,
    "provider": "aws-s3",
    "provider_metadata": null,
    "created_at": "2022-12-21T16:15:58.000Z",
    "updated_at": "2022-12-21T16:15:58.000Z",
    "related": []
  }
]
    */

    /**
 * fetch("https://api.vetrinas.ly/content-manager/collection-types/application::products.products", {
  "headers": {
    "accept": "",
    "accept-language": "en-US,en;q=0.9,ar;q=0.8,tr;q=0.7",
    "authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjcxNjM5Mjc3LCJleHAiOjE2NzQyMzEyNzd9.njgAjSwgwSB7I1XTOI_b7TxgthzfmAejJK2V54yauW0",
    "cache-control": "no-cache",
    "content-type": "application/json",
    "pragma": "no-cache",
    "sec-ch-ua": "\"Not?A_Brand\";v=\"8\", \"Chromium\";v=\"108\", \"Google Chrome\";v=\"108\"",
    "sec-ch-ua-mobile": "?0",
    "sec-ch-ua-platform": "\"macOS\"",
    "sec-fetch-dest": "empty",
    "sec-fetch-mode": "cors",
    "sec-fetch-site": "same-origin"
  },
  "referrer": "https://api.vetrinas.ly/admin/plugins/content-manager/collectionType/application::products.products/create",
  "referrerPolicy": "strict-origin-when-cross-origin",
  "body": "{\"discount\":0,\"attrs\":null,\"inputs\":null,\"rating\":0,\"isDeleted\":false,\"featured\":false,\"allow_out_of_stock_sell\":false,\"main_image\":11193}",
  "method": "POST",
  "mode": "cors",
  "credentials": "include"
});
 * 
 */
  };

  return (
    <Container>
      <Stack>
        TenantProducts
        <p>select images</p>
        {/* <input
        accept="image/*"
        value={files}
        onChange
        name="images"
        multiple
        type="file"
      /> */}
        <Dropzone
          allowMultiple
          onFilesChanged={(_files) => setFiles(_files)}
          selectedFiles={files}
        />
        {count > 0 && <p>{count} downloaded</p>}
        <Button
          variant='contained'
          onClick={add}
          disabled={isDownloading || files.length === 0}
        >
          upload
        </Button>
      </Stack>
    </Container>
  );
}
