import { Card, Container, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import TableWrapper from "src/components/TableWrapper";
import { getLoginStats } from "src/utils/AdminApi";

export default function DailyLoginStats() {
  const { data = [] } = useQuery("login-stats", () => getLoginStats(), {
    refetchOnWindowFocus: false,
  });

  console.log("data", data);
  return (
    <Card sx={{ p: 2 }}>
      <Typography variant="h3">احصائيات الاستخدام</Typography>
      <TableWrapper
        align="start"
        headerColumns={["التاريخ", "عدد المستخدمين"]}
        dataRows={data.map((s) => {
          return [s.date.split("T")?.[0] ?? "", , s.users_count];
        })}
      />
    </Card>
  );
}
