import { DateTime } from 'luxon';

export const dateRangeFilters = {
  today: {
    key: 'today',
    label: 'اليوم',
    fromDate: DateTime.utc().toISODate(),
    toDate: DateTime.utc().plus({ days: 1 }).toISODate(),
  },
  thisWeek: {
    key: 'thisWeek',
    label: 'الاسبوع',
    fromDate: DateTime.utc().startOf('week').toISODate(),
    toDate: DateTime.utc().startOf('week').plus({ weeks: 1 }).toISODate(),
  },
  thisMonth: {
    key: 'thisMonth',
    label: 'الشهر',
    fromDate: DateTime.utc().startOf('month').toISODate(),
    toDate: DateTime.utc().startOf('month').plus({ months: 1 }).toISODate(),
  },
  thisYear: {
    key: 'thisYear',
    label: 'السنة',
    fromDate: DateTime.utc().startOf('year').toISODate(),
    toDate: DateTime.utc().startOf('year').plus({ years: 1 }).toISODate(),
  },
};
