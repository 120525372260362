import { DateTime } from 'luxon';

export const getDateTimeString = (dateStr) => {
  if (!dateStr) return '';

  const arr = dateStr.split('T');
  const date = arr[0];
  const time = arr[1].replace('.000Z', '');
  return `التاريخ ${date} الوقت ${time}`;
};

// // 2022-02-23T14:52:53.000Z

export const getDateTime = (dateStr) => {
  if (!dateStr) return '';

  const arr = dateStr.split('T');
  const date = arr[0];
  const time = arr[1].replace('.000Z', '');
  return [date, time];
};

export const getCurrentUTCDate = () => DateTime.now().toUTC().startOf('day');

export const getDaysDifference = (dateString) => {
  return DateTime.fromISO(dateString, {
    zone: 'utc',
  }).diff(getCurrentUTCDate(), ['days'])?.values?.days;
};
