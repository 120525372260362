import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';

export default function ToggleButtons({ sx = {}, value, onChange, buttons }) {
  return (
    <ToggleButtonGroup
      sx={sx}
      value={value}
      exclusive
      onChange={onChange}
      aria-label='text alignment'
    >
      {buttons.map((item) => {
        return (
          <ToggleButton
            key={item.value}
            value={item.value}
            aria-label='left aligned'
          >
            {item.label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
