export const updateArray = (array, updates, key) => {
  const newArray = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === updates[key]) {
      newArray.push(updates);
    } else {
      newArray.push(array[i]);
    }
  }
  return newArray;
};

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export const emailIsValid = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

/**
 * 
 * {
    "statusCode": 400,
    "error": "Bad Request",
    "message": [
        {
            "messages": [
                {
                    "id": "Auth.form.error.user.not-exist",
                    "message": "This email does not exist."
                }
            ]
        }
    ],
    "data": [
        {
            "messages": [
                {
                    "id": "Auth.form.error.user.not-exist",
                    "message": "This email does not exist."
                }
            ]
        }
    ]
}
 * @param {*} errorResponseBody server response body 
 * @returns string
 */
export const formatStrapiError = (errorResponseBody) => {
  // if error is string this mean error already handled by defult formatter ( ex: no connection )
  if (typeof errorResponseBody === 'string') return errorResponseBody;
  if (typeof errorResponseBody === 'object') {
    const { statusCode, message } = errorResponseBody;

    if (statusCode === 401 || statusCode === 403)
      return 'ليس لديك صلاحية للقيام بهذه العملية';

    if (statusCode >= 500) return 'حدث خطا غير متوقع';

    const errors = [];
    if (message) {
      if (Array.isArray(message)) {
        message.forEach((m) => m.messages?.forEach((e) => errors.push(e)));
      } else if (typeof message === 'string') {
        return message;
      } else {
        // object error
        errors.push(message);
      }
    }
    // translate generic errors , TODO - should be done on backend
    for (let i = 0; i < errors.length; i++) {
      if (errors[i].id === 'Auth.form.error.user.not-exist') {
        errors[i].message = 'هذا البريد الالكتروني غير مسجل من قبل';
      } else if (errors[i].id === 'Auth.form.error.email.format') {
        errors[i].message = 'يرجي ادخال بريد الكتروني صحيح';
      } else if (errors[i].id === 'Auth.form.error.code.provide') {
        errors[i].message = 'انتهت صلاحية رابط تغير كلمة المرور';
      }
    }
    // map all errors in one string
    const errorsString = errors.map((e) => e.message).join('\n');
    // console.log('errorssss',errorsString)
    return errorsString;
  }
};

// export const formatStrapiError = (err) => {
//   if (err.response) {
//     // Request made and server responded
//     const { status, data, headers } = err.response;
//     console.log('server error msg : ', data);
//     // console.log('server error msg : ',err.response);
//     if (status === 403 || status === 401) {
//       return 'ليس لديك صلاحية للقيام بهذه العملية';
//     } else if (status === 400) {
//       // if(url === '/auth/local/register' || url.match(/\/auth\/[a-z]+\/callback/g))
//       // return {id:'error',message: i18n.t("errors.emailExist")}
//       // else if(url === '/auth/local')
//       // return {id:'error',message: i18n.t("errors.emailOrPassError")}
//       const server_message = err.response.data.message;
//       const errors = [];
//       if (server_message) {
//         if (Array.isArray(server_message)) {
//           server_message.forEach((m) =>
//             m.messages?.forEach((e) => errors.push(e))
//           );
//         } else if (typeof server_message === 'string') {
//           return server_message;
//         } else {
//           // object error
//           errors.push(server_message);
//         }
//       }

//       // console.log('bad request with status code 400 and errors => ',errors)
//       // handle generic errors
//       for (let i = 0; i < errors.length; i++) {
//         if (errors[i].id === 'Auth.form.error.user.not-exist') {
//           return 'هذا البريد الالكتروني غير موجود';
//         }
//       }

//       const errorsString = errors.map((e) => e.message).join('\n');
//       // console.log('errorssss',errorsString)

//       return errorsString;
//     }

//     return 'حدث خطا غير متوقع';
//   } else if (err.request) {
//     // console.log('did not receive a response ');
//     return 'يوجد مشكلة في الاتصال بالسيرفر';
//   } else {
//     // console.log('unable to make the request ',err.message);
//     return 'يوجد مشكلة في الاتصال بالسيرفر';
//   }
// };

export const isNullOrEmpty = (param) => {
  // null check will work the same way for different types
  if (param == null) return true;

  if (typeof param === 'string' || Array.isArray(param)) {
    return param.length === 0;
  }

  return Object.keys.length === 0;
};
