import { Stack } from '@mui/material';

export const LoadingView = () => (
  <Stack
    spacing={4}
    minHeight='100vh'
    justifyContent='center'
    alignItems='center'
  >
    اصبر شوية
  </Stack>
);
