import { Button, CircularProgress } from '@mui/material';
import React from 'react';

export default function SubmitButton({
  onClick,
  isSubmitting,
  disabled,
  ...props
}) {
  if (isSubmitting) return <CircularProgress color='secondary' />;
  else
    return (
      <Button
        onClick={onClick}
        sx={{ mt: 4 }}
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        color='secondary'
        disabled={disabled}
      >
        {props.children}
      </Button>
    );
}
