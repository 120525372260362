import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
// material
import { Button, Card, Container, Divider, Stack, Typography } from "@mui/material"
import Page from "src/components/Page"
import useStrapiAdminQuery from "src/hooks/useStrapiQuery"
import _ from "lodash"
import { requestUserInputs } from "vetrina-ui"
import { strapiAdminCreate, strapiAdminDelete, strapiAdminUpdate } from "src/utils/strapiAdminApi"

export default function Settings({}) {
  const { data, refetch } = useStrapiAdminQuery({
    contentType: "settings",
  })
  const { data: tenants, refetch: refetchSite } = useStrapiAdminQuery({
    contentType: "tenant",
    query: {
      _where: {
        host: "demo.vetrinas.ly",
      },
    },
  })

  const settings = data?.results ?? []
  const demoSite = tenants?.results[0]
  const grouppedSettings = _.groupBy(settings, "resource")
  console.log("🚀 ~ Settings ~ grouppedSettings:", grouppedSettings)

  const navigate = useNavigate()
  const [state, setState] = useState()
  useEffect(() => {}, [])

  return (
    <Page title="فترينا">
      <Container maxWidth="md">
        <Card sx={{ p: 3 }}>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">اعدادات المتجر </Typography>
              <Button
                variant="contained"
                onClick={async () => {
                  const values = await requestUserInputs("اضافة اعداد جديد", [
                    "resource",
                    "key",
                    "label",
                    { name: "private", label: "private", type: "checkbox", value: false },
                  ])

                  await strapiAdminCreate("settings", values)
                  refetch()
                }}
              >
                اضافة
              </Button>
            </Stack>

            <Stack spacing={2}>
              {Object.entries(grouppedSettings).map(([resource, resourceSettings]) => {
                return (
                  <>
                    <Typography variant="h5">{resource}</Typography>
                    {resourceSettings.map((item) => {
                      return (
                        <Stack alignItems="center" direction="row" justifyContent="space-between" key={item.id}>
                          <Typography variant="body">{item.key}</Typography>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={async () => {
                              await strapiAdminDelete("settings", item.id)
                              refetch()
                            }}
                          >
                            delete
                          </Button>
                        </Stack>
                      )
                    })}
                    <Divider />
                  </>
                )
              })}
            </Stack>
          </Stack>
        </Card>

        <Card sx={{ p: 3, my: 3 }}>
          <Typography variant="h4">متجر فترينا</Typography>

          {Object.entries(grouppedSettings).map(([resource, resourceSettings]) => {
            return (
              <>
                <Typography variant="h5">{resource}</Typography>
                <Stack spacing={2}>
                  {resourceSettings.map((item) => {
                    let value = demoSite.config?.[item.resource]?.[item.key]

                    return (
                      <Stack direction="row" justifyContent="space-between" key={item.id}>
                        <Typography variant="body">{item.key}</Typography>
                        <Stack spacing={1} alignItems="center" direction="row" justifyContent="space-between">
                          <Typography variant="body">{value?.toString() ?? "no value"}</Typography>
                          <Button
                            variant="outlined"
                            onClick={async () => {
                              const values = await requestUserInputs("تعديل القيمة", [{ name: "value", type: item.type ?? "text", value }])

                              await strapiAdminUpdate("tenant", demoSite.id, {
                                config: {
                                  ...demoSite.config,
                                  [item.resource]: {
                                    [item.key]: values.value,
                                  },
                                },
                              })
                              refetchSite()
                            }}
                          >
                            Set
                          </Button>
                        </Stack>
                      </Stack>
                    )
                  })}
                  {JSON.stringify(demoSite.config?.[resource])}
                </Stack>
              </>
            )
          })}
        </Card>
      </Container>
    </Page>
  )
}
