import PropTypes from 'prop-types';
// material
import { Box, Typography, Stack } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx, color = 'primary' }) {
  // return <Box component="img" src="/static/logo.png" sx={{ width: 40, height: 40, ...sx }} />;
  return (
    <Stack direction='row' justifyContent='center'>
      <Typography sx={{ textDecoration: 'none' }} variant='h3' color={color}>
        ادمن فترينا
      </Typography>
    </Stack>
  );
}
