import { Container, Typography } from "@mui/material"
import React from "react"
import { useQuery } from "react-query"
import { toast } from "react-toastify"
import { DynamicForm } from "vetrina-ui"
import { LoadingView } from "src/components/LoadingView"
import { broadcastNotification } from "src/utils/AdminApi"
import { strapiAdminFindMany } from "src/utils/strapiAdminApi"
import { string } from "yup"

export default function NotificationsPage() {
  const { data: tenantsRes, isLoading } = useQuery(
    ["tenants"],
    () =>
      strapiAdminFindMany("tenant", {
        _sort: "lastLoginAt",
        _limit: 50,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )

  if (isLoading) return <LoadingView />

  return (
    <Container>
      <Typography>Broadcast Notifications</Typography>
      <DynamicForm
        onSubmit={async (v) => {
          try {
            const res = await broadcastNotification(v)
            toast.success("تم ارسال الاشعار بنجاح")
          } catch (err) {
            toast.error("something went wrong")
            console.log(err)
          }
        }}
        config={{
          inputs: [
            {
              name: "title",
              label: "title",
              placeholder: "title",
              type: "text",
              value: "",
              validation: string().required(),
            },
            {
              name: "body",
              label: "body",
              placeholder: "body",
              type: "text",
              value: "",
              validation: string().required(),
            },
            {
              name: "type",
              label: "type",
              placeholder: "type",
              type: "text",
              value: "",
              validation: string(),
            },
            {
              name: "link",
              label: "link",
              placeholder: "link",
              type: "text",
              value: "",
              validation: string().matches(
                /((https?):\/\/)?(www.)?[a-zA-Z0-9]+(\.[a-zA-Z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                "يرجي ادخال رابط صحيح"
              ),
            },
            {
              name: "website",
              label: "website",
              placeholder: "website",
              type: "picker",
              options: [{ site_name: "all", id: 0 }, ...tenantsRes.results],
              labelKey: "site_name",
              valueKey: "id",
              value: "",
            },
          ],
        }}
      />
    </Container>
  )
}
