import { useState, useEffect } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
// material
import { Autocomplete, Box, Button, Card, CircularProgress, Container, Divider, Stack, TextField, Typography } from "@mui/material"
import Page from "src/components/Page"
import { useQuery } from "react-query"
import { fetchContent } from "src/utils/AdminApi"
import { makeHttpRequest } from "src/utils/requestBuilder"
import { toast } from "react-toastify"
import { getMatchedCity } from "src/utils/searchForMatchedCity"
import { isNullOrEmpty } from "src/utils/helperMethods"
import { requestUserInputs } from "src/components/BasicInputsModal"
export default function ProviderMapping({}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const cityId = searchParams.get("cityId")

  // TODO fetch all the cities from provider
  const { data: cities = [], citiesIsLoading } = useQuery("cities", () => makeHttpRequest("/cities?_limit=-1"), {
    refetchOnWindowFocus: false,
  })
  const { data: citiesMapping, isLoading } = useQuery(
    "skyex-cities-mapping",
    () => fetchContent("cities-mapping.cities-mapping?provider=skyex&pageSize=-1"),
    {
      refetchOnWindowFocus: false,
    }
  )
  const { data: providerCities = [], providerCitiesIsLoading } = useQuery(
    "skyex-cities",
    () => makeHttpRequest("/getProviderCities?provider=skyex"),
    {
      refetchOnWindowFocus: false,
    }
  )

  const filteredCities = cities.filter((c) => {
    if (cityId != null) {
      return c.parent?.id == cityId
    } else {
      return c.parent == null
    }
  })
  const [results, setResults] = useState([])

  const autoMap = async () => {
    if (!window.confirm("are you sure you want to auto map?")) return

    const providerCities_needs_mapping = providerCities.filter((providerCity) => {
      let mappedCity = citiesMapping?.results?.find((c) => c.map_name == providerCity.name)
      return mappedCity == null
    })

    console.log("providerCities_needs_mapping.length", providerCities_needs_mapping.length)
    if (providerCities_needs_mapping.length === 0) return alert("nothing to map ")

    for (let providerCity of providerCities_needs_mapping) {
      const matched_city = getMatchedCity(providerCity.name, filteredCities, "name", 0.7)
      if (!matched_city) {
        console.log("no match found", providerCity.name)
        continue
      }

      try {
        await makeHttpRequest("/cities-mappings", "post", {
          provider: "skyex",
          city: matched_city.id,
          map_id: `${providerCity.id}`,
          map_name: `${providerCity.name}`,
        })
      } catch (err) {}
    }
  }

  const testMapping = async (c, d) => {
    for (let c of filteredCities) {
      const city = c.parent ? c.parent : c
      const district = c.parent ? c : undefined

      let url = `/shipping/skyex/price?city=${city.name}`
      if (district) url += `&district=${district.name}`

      try {
        const res = await makeHttpRequest(url)

        setResults((r) => [
          ...r,
          {
            name: district?.name ?? city.name,
            ...res,
          },
        ])
      } catch (err) {
        setResults((r) => [
          ...r,
          {
            name: district?.name ?? city.name,
            success: false,
          },
        ])
      }
    }
  }

  if (providerCitiesIsLoading || isLoading) return "loading"
  return (
    <Page title="فترينا">
      <Container>
        <Card sx={{ p: 3 }}>
          <Stack spacing={2}>
            <Typography variant="h4">المدن </Typography>

            <Stack spacing={1} direction="row" justifyContent="space-between">
              <Button onClick={autoMap}>auto map</Button>
              <Button onClick={testMapping}>test mapping</Button>
            </Stack>
            <Stack spacing={4}>
              {filteredCities.map((city, idx) => {
                let mappedCity = citiesMapping?.results?.find((c) => c.city.id == city.id)
                let mappedResult = results?.find((c) => c.name == city.name)
                const parentCityId = cityId ? citiesMapping?.results?.find((c) => c.city.id == cityId)?.map_id : null
                return <CityRow index={idx} city={city} mappedCity={mappedCity} result={mappedResult} parentCityId={parentCityId} />
              })}
            </Stack>
          </Stack>
        </Card>

        <Box my={14}>
          <ProviderDropdown />
        </Box>
      </Container>
    </Page>
  )
}

/**
 *
 * @param {object} param0
 * @param {import("vetrina-types").Attributes['cities-mapping']} param0.mappedCity
 * @param {import("vetrina-types").Attributes['cities']} param0.city
 * @returns
 */
const CityRow = ({ city, mappedCity, index, result, parentCityId }) => {
  const { data: providerCities = [], providerCitiesIsLoading } = useQuery(
    "skyex-cities",
    () => makeHttpRequest("/getProviderCities?provider=skyex"),
    {
      refetchOnWindowFocus: false,
    }
  )

  const { data: providerDistricts = [] } = useQuery(
    ["skyex-districts", parentCityId],
    () => makeHttpRequest(`/getProviderDistricts?cityId=${parentCityId}&provider=skyex`),
    {
      refetchOnWindowFocus: false,
      enabled: !!parentCityId,
    }
  )

  const options = parentCityId ? providerDistricts : providerCities

  const [selectedValue, setSelectedValue] = useState(mappedCity?.map_name ?? "")
  const [updatedValue, setUpdateValue] = useState(selectedValue)

  return (
    <Stack spacing={2}>
      <Stack>
        <Stack sx={{ color: result?.success === false && "red" }} spacing={2} direction="row" alignItems="center" key={city.id}>
          <Typography width={30}>{index + 1} </Typography>
          <Box width={225}>
            {isNullOrEmpty(city.districts) ? (
              <Typography>{city.name} </Typography>
            ) : (
              <a href={`/provider-mapping?cityId=${city.id}`}>
                <Typography>{city.name} </Typography>
              </a>
            )}
          </Box>

          {/* <Typography width={30}>{city.id} </Typography> */}
          {/* <Typography width={100}>{mappedCity ? mappedCity.city.name : "none"} </Typography> */}

          {/* <Button
            sx={{ width: 250 }}
            onClick={async () => {
              const name = prompt("enter city name")
    
              makeHttpRequest("/cities", "post", {
                name: name,
              })
    
              toast.success("city created")
              refetchCities()
            }}
            size="small"
            variant="contained"
          >
            add new city
          </Button> */}
          <Autocomplete
            size="small"
            fullWidth
            value={selectedValue}
            onChange={(e, n) => setSelectedValue(n?.label ?? "")}
            options={options.map((r) => ({ label: r.name, value: r.id }))}
            renderInput={(params) => <TextField width={250} {...params} />}
            isOptionEqualToValue={(option, value) => {
              // console.log(option, value)
              return option.label == selectedValue
            }}
            getOptionLabel={(value) => {
              if (typeof value === "object") {
                return `${value.label} ${value.value}`
              } else {
                return value
              }
            }}
          />
          {/* {selectedValue} */}

          {/* <Button
            onClick={async () => {
              const matched_city = getMatchedCity(city.name, providerCities, "name", 0.7)
              if (!matched_city) return alert("no match found")
              makeHttpRequest("/cities-mappings", "post", {
                provider: "skyex",
                city: city.id,
                map_id: `${matched_city.id}`,
                map_name: `${matched_city.name}`,
              })
    
              toast.success("mapping created")
    
              setUpdateValue(matched_city.name)
            }}
            size="small"
            variant="contained"
          >
            auto
          </Button> */}

          <Button
            onClick={async () => {
              const values = await requestUserInputs("Mapping details", [
                ["map_id", mappedCity.map_id],
                ["map_name", mappedCity.map_name],
                ["map_parent_id", mappedCity.map_parent_id],
                ["map_parent_name", mappedCity.map_parent_name],
              ])
              console.log("🚀 ~ CityRow ~ values:", values)
              values.provider = "skyex"
              values.city = city.id

              if (mappedCity) {
                await makeHttpRequest(`/cities-mappings/${mappedCity.id}`, "put", values)
                toast.success("updated")
              } else {
                await makeHttpRequest("/cities-mappings", "post", values)
                toast.success("mapping created")
              }
              window.location.reload()
            }}
          >
            manually
          </Button>
          <Button
            disabled={mappedCity?.map_name === selectedValue || updatedValue === selectedValue}
            onClick={async () => {
              if (mappedCity && !selectedValue) {
                return
              }
              const selectedProviderCity = options.find((c) => c.name === selectedValue)
              const input = {
                provider: "skyex",
                city: city.id,
                map_id: `${selectedProviderCity.id}`,
                map_name: `${selectedProviderCity.name}`,
              }

              if (mappedCity) {
                await makeHttpRequest(`/cities-mappings/${mappedCity.id}`, "put", input)
                toast.success("updated")
              } else {
                await makeHttpRequest("/cities-mappings", "post", input)
                toast.success("mapping created")
              }

              setUpdateValue(selectedValue)
            }}
            size="small"
            variant="contained"
          >
            Confirm
          </Button>

          <Button
            color="error"
            disabled={mappedCity == null}
            onClick={async () => {
              if (window.confirm("are u sure?")) {
                await makeHttpRequest(`/cities-mappings/${mappedCity.id}`, "delete")
                window.location.reload()

                return toast.success("removed")
              }
            }}
          >
            delete
          </Button>

          <button
            onClick={async () => {
              const c = city.parent ? city.parent : city
              const d = city.parent ? city : undefined

              let url = `/shipping/skyex/price?city=${c.name}`
              if (d) url += `&district=${d.name}`
              try {
                const res = await makeHttpRequest(url)
                alert(`price=${res.price}`)
              } catch (err) {
                alert("fail")
              }
            }}
          >
            price
          </button>
        </Stack>

        {mappedCity?.map_parent_id && (
          <Typography variant="caption" align="center">
            {mappedCity.map_parent_id} {mappedCity.map_parent_name} {"->"} {mappedCity.map_id} {mappedCity.map_name}
          </Typography>
        )}
      </Stack>

      {result && (
        <Stack sx={{ color: result.success ? "green" : "red" }} spacing={3} direction="row">
          <Typography>السعر: {result.price}</Typography>
          <Typography>
            المدينة: {result.meta?.address?.state_name} {result.meta?.address?.state_id}
          </Typography>
          <Typography>
            المنطقة: {result.meta?.address?.place_name} {result.meta?.address?.place_id}
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

// used to test based on their cities/district structure, in case there's a problem
const ProviderDropdown = () => {
  const { data: providerCities = [], providerCitiesIsLoading } = useQuery(
    "skyex-cities",
    () => makeHttpRequest("/getProviderCities?provider=skyex"),
    {
      refetchOnWindowFocus: false,
    }
  )

  const [selectedCity, setSelectedCity] = useState("")
  const [selectedDistrict, setSelectedDistrict] = useState("")

  const { data: providerDistricts = [], isLoading: providerDistrictsIsLoading } = useQuery(
    ["skyex-districts", selectedCity],
    () => makeHttpRequest(`/getProviderDistricts?provider=skyex&cityId=${selectedCity}`),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedCity,
      onSuccess: (d) => {
        console.log("d", d)
        setSelectedDistrict(d[0]?.id ?? "")
      },
    }
  )

  if (providerCitiesIsLoading) return "loading"

  return (
    <div>
      <Typography>Provider API debugging</Typography>
      <Stack spacing={1} direction="row" justifyContent="space-between">
        <Autocomplete
          size="small"
          fullWidth
          value={providerCities.find((c) => c.id == selectedCity)?.name ?? ""}
          onChange={(e, n) => setSelectedCity(n?.value)}
          options={providerCities.map((r) => ({ label: r.name, value: r.id }))}
          renderInput={(params) => <TextField width={250} {...params} />}
          isOptionEqualToValue={(option, value) => {
            // console.log(option, value)
            return option.value == selectedCity
          }}
        />
        <Autocomplete
          size="small"
          fullWidth
          value={providerDistricts.find((c) => c.id == selectedDistrict)?.name ?? ""}
          onChange={(e, n) => setSelectedDistrict(n.value)}
          options={providerDistricts.map((r) => ({ label: r.name, value: r.id }))}
          renderInput={(params) => <TextField width={250} {...params} />}
          isOptionEqualToValue={(option, value) => {
            // console.log(option, value)
            return option.value == selectedDistrict
          }}
        />
        {providerDistrictsIsLoading && <CircularProgress />}
      </Stack>

      <Stack mt={2} spacing={3} direction="row">
        <p>city id: {selectedCity}</p>
        <p>district id: {selectedDistrict}</p>
      </Stack>
      <Button
        variant="contained"
        onClick={async () => {
          let url = `/getShippingPriceWithoutMapping?provider=skyex&city=${selectedCity}&district=${selectedDistrict}`

          try {
            const res = await makeHttpRequest(url)
            alert(`price=${res.price}`)
          } catch (err) {
            alert("fail")
          }
        }}
      >
        get price
      </Button>
    </div>
  )
}

// TODO: show the parent city
// TODO add functionality to calculate price
