import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
// material
import { Button, Card, Container, Divider, Stack, TextField, Typography } from "@mui/material"
import Page from "src/components/Page"
import { makeHttpRequest } from "src/utils/requestBuilder"
import { toast } from "react-toastify"
import CustomFields from "src/components/CustomFields"

export default function Dhl({}) {
  const navigate = useNavigate()
  const [state, setState] = useState()
  const [values, setValues] = useState({
    items: [
      {
        id: 1,
        weight: 1,
      },
      {
        id: 2,
        weight: 1,
      },
      {
        id: 3,
        weight: 1,
      },
      {
        id: 4,
        weight: 1,
      },
      {
        id: 5,
        weight: 1,
      },
    ],
    count: 1,
  })
  const [result, setResult] = useState({ isLoading: false })

  useEffect(() => {}, [])

  return (
    <Page title="فترينا">
      <Container maxWidth="md">
        <Card sx={{ p: 3, mb: 14 }}>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Dhl </Typography>
            </Stack>

            <Typography variant="h4">
              يوجد ٤ احجام للصناديق الارتفاع والعرض ٢٨ بينما الطول يختلف ٦ لبوكس واحد ١٣ بوكسين ٢٠ = ٣ بوكسات ٢٦ = ٤ بوكسات
            </Typography>

            {/* number of items input */}
            {/* <TextField label="عدد القطع" value={values.count} onChange={(e) => setValues({ ...values, count: e.target.value })} /> */}

            <CustomFields
              fields={[
                {
                  label: "الوزن",
                  key: "weight",
                },
              ]}
              title={"المنتجات "}
              // errors={errors.attrs}
              // touches={touched.attrs}
              // setFieldTouched={setFieldTouched}
              value={values?.items}
              onChange={(newValue) => {
                setValues((v) => {
                  return { ...v, items: newValue }
                })
              }}
            />

            <Button
              variant="contained"
              disabled={result.isLoading}
              onClick={async () => {
                setResult((r) => ({ ...r, isLoading: true }))
                // setOptionsState((s) => ({ ...s, isLoading: true }))
                try {
                  const res = await makeHttpRequest(true ? "getTestCityDeliveryOptions" : "/getCityDeliveryOptions", "post", {
                    country: "DE",
                    city: "DER",
                    total_weight: 1,
                    items: values.items,
                  })

                  const dhl = res.find((r) => r.id === "dhl")
                  if (!dhl) return toast.error("no price")
                  setResult({
                    price: dhl.price + dhl.currency,
                    packages: dhl.meta.request.packages,
                  })
                  // setOptionsState((s) => ({ ...s, isLoading: false, options: res }))
                } catch (err) {
                  toast.error(err?.response?.data?.message ?? err?.message ?? "something went wrong")
                  // setOptionsState((s) => ({ ...s, isLoading: false }))
                }

                setResult((r) => ({ ...r, isLoading: false }))
              }}
            >
              get price
            </Button>

            {result?.price && (
              <>
                <Typography variant="h5" mt={3}>
                  price: {result.price}
                </Typography>
                {result.packages.map((item, index) => {
                  const dimensions = item.dimensions
                  return (
                    <>
                      <Stack direction="row" justifyContent="space-between" key={index}>
                        <Typography>
                          L{dimensions.length} W{dimensions.width} H{dimensions.height}
                        </Typography>
                        <Typography>weight: {item.weight}</Typography>
                      </Stack>
                      <Divider />
                    </>
                  )
                })}
              </>
            )}

            {/* button to submit */}
          </Stack>
        </Card>
      </Container>
    </Page>
  )
}

const packagingAlgo = (count) => {
  const BOXES = {
    1: "box1 28x28x6",
    2: "box2 28x28x13",
    3: "box3 28x28x20",
    4: "box4 28x28x26",
  }
  const boxes = []

  let c = count
  while (c > 4) {
    c -= 4
    boxes.push(BOXES[4])
  }

  return [...boxes, BOXES[c]]
}
