import { Link as RouterLink, Outlet } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
// components
import { Box } from '@mui/material';
import Logo from '../components/Logo';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  // lineHeight: 0,
  minHeight: 80,
  backgroundColor: theme.palette.primary.main,
  width: '100%',
  padding: 16,
  display: 'flex',
  alignItems: 'center',
  // [theme.breakpoints.up('sm')]: {
  //   padding: theme.spacing(5, 5, 0)
  // }
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  return (
    <>
      <HeaderStyle>
        <RouterLink
          to='/'
          style={{
            textDecoration: 'none',
          }}
        >
          <Logo color='white' />
        </RouterLink>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
