import { useState } from "react"
import { useQuery } from "react-query"
import { fetchContent } from "src/utils/AdminApi"
// mui
import { Button, Card, Container, Stack, TextField, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { Link } from "react-router-dom"
import TableWrapper from "src/components/TableWrapper"
import { getDaysDifference } from "src/utils/date"
import { getSubscripitonStatusString } from "src/lib/subscription"
import { useDebounce } from "src/hooks/useDebounce"
function useNewPage() {
  // example of using redux state

  const [searchBoxValue, setSearchBoxValue] = useState("")
  const debouncedSearchValue = useDebounce(searchBoxValue, 500)

  const [date, setDate] = useState({
    from: DateTime.now().minus({ days: 365 }).toUTC(),
    to: DateTime.now().minus({ days: 4 }).toUTC(),
  })

  // console.log(date);

  const [sortBy, setSortBy] = useState("lastLoginAt:desc")

  // // to make http request
  // let { data: admins } = useQuery(`str`, getAdmins);

  const queryResult = useQuery(
    ["new_stores", debouncedSearchValue],
    () => fetchContent(`tenant.tenant?_q=${debouncedSearchValue}&lastLoginAt_gte=${date.from}&lastLoginAt_lt=${date.to}&_sort=${sortBy}`),
    {
      enabled: date.from !== "" && date.to !== "",
    }
  )

  const updateMonth = (number) => {
    setDate({
      from: DateTime.fromFormat(date.from, "yyyy-MM-dd").plus({ month: number }).startOf("month").toFormat("yyyy-MM-dd"),
      to: DateTime.fromFormat(date.to, "yyyy-MM-dd").plus({ month: number }).endOf("month").toFormat("yyyy-MM-dd"),
    })
  }

  const onClearButtonClicked = () => {
    setSearchBoxValue("")
  }

  return {
    searchValue: searchBoxValue,
    setSearchValue: setSearchBoxValue,
    queryResult,
    sortBy,
    setSortBy,
    date,
    setDate,
    updateMonth,
    onClearButtonClicked,
  }
}

export default function InActiveTenantsPage() {
  const { onClearButtonClicked, searchValue, setSearchValue, queryResult, sortBy, setSortBy, date, setDate, updateMonth } = useNewPage()
  // console.log(test.data.data.results);
  // console.log(admins);
  const tenants = queryResult?.data?.results ?? []
  return (
    <Container>
      <Card sx={{ p: 2 }}>
        <Typography variant="h3" gutterBottom>
          المتاجر الغير نشطة
        </Typography>
        <Typography gutterBottom>سيتم عرض المتاجر التي لم تدخل علي المنصة خلال اخر 4 ايام</Typography>

        <Stack alignItems="center" spacing={1} direction="row">
          <TextField value={searchValue} onChange={(e) => setSearchValue(e.target.value)} fullWidth name="q" placeholder="البحث" type="text" />
          <Button onClick={onClearButtonClicked}>clear</Button>
        </Stack>

        <Typography gutterBottom>عدد المتاجر = {queryResult?.data?.results?.length ?? 0}</Typography>

        <TableWrapper
          align="start"
          headerColumns={["المتجر", "اخر دخول", "رقم الهاتف", "تاريخ التسجيل"]}
          dataRows={tenants.map((s) => {
            const phone = s.config?.storePhone ?? s.admins?.[0]?.phone ?? "no number"
            return [
              <Link style={{ textDecoration: "none", color: "#0D6659" }} to={`/tenants/${s.id}`}>
                {s.site_name}
              </Link>,
              Math.abs(getDaysDifference(s.lastLoginAt)),
              <Link type="tel" style={{ textDecoration: "none", color: "#0D6659" }} to={`tel:${phone}`}>
                {phone}
              </Link>,

              s.created_at.split("T")?.[0] ?? "",
            ]
          })}
        />
      </Card>
    </Container>
  )
}
