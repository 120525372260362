// material
import { Grid, Stack, Card, Typography } from '@mui/material';
export const StatsItem = ({ label, value }) => {
  return (
    <Grid item xs={6} sm={4} md={3}>
      <Card sx={{ p: 2 }}>
        <Stack alignItems='center'>
          <Typography>{label}</Typography>
          <Typography color='primary' variant='h4'>
            {value}
          </Typography>
        </Stack>
      </Card>
    </Grid>
  );
};
