import React from "react"
import { toast } from "react-toastify"
import { DynamicForm } from "vetrina-ui"
import { makeHttpRequest } from "src/utils/requestBuilder"
import { requestUserInputs } from "vetrina-ui"
import { Button, Card } from "@mui/material"
import AccordionWrapper from "src/components/AccordionWrapper"
import { set } from "lodash"
/**
 *
 * @param {object} props0
 * @param {import("vetrina-types").Attributes['tenant']} props0.tenant
 */
export default function TenantConfigForm({ tenant, refetch }) {
  return (
    <div>
      <DynamicForm
        submitButtonText="تحديث"
        config={{
          inputs: [
            {
              label: "currency",
              name: "currency",
              type: "picker",
              value: tenant.config?.currency ?? "",
              options: ["د.ل", "$", "€"],
            },
            {
              label: "hide out of stock products from store",
              name: "shouldHideOutOfStock",
              type: "checkbox",
              value: tenant.config?.shouldHideOutOfStock ?? false,
            },
            {
              label: "facebook pixel id",
              name: "fb_pixel__id",
              value: tenant.config?.fb_pixel?.id ?? "",
            },
            {
              label: "facebook pixel domain verification code",
              name: "fb_pixel__facebook_domain_verification",
              value: tenant.config?.fb_pixel?.facebook_domain_verification ?? "",
            },
          ],
        }}
        onSubmit={async (v) => {
          const result = {}

          Object.keys(v).forEach((key) => {
            if (key.includes("__")) {
              set(result, key.split("__"), v[key])
            } else {
              result[key] = v[key]
            }
          })

          try {
            await makeHttpRequest("/tenants/" + tenant.id, "put", {
              config: {
                ...tenant.config,
                ...result,
              },
            })
            toast.success("updated successfully")
          } catch (err) {
            toast.error("error occur, check logs")
            console.log(err)
          }
        }}
      />

      <Button
        sx={{ mt: 2 }}
        onClick={() => {
          requestUserInputs("add key/value pair", ["key", "value"])
            .then(async (values) => {
              await makeHttpRequest("/tenants/" + tenant.id, "put", {
                config: set({ ...tenant.config }, values.key, values.value),
              })
              toast.success("added successfully")
              refetch()
            })
            .catch((err) => {
              toast.error("error occur, check logs")
              console.log(err)
            })
        }}
      >
        + key/value pair
      </Button>

      <AccordionWrapper parent="config (json)">
        <Card style={{ direction: "ltr", backgroundColor: "rgba(45,45,48,0.94)" }} sx={{ p: 3, color: "white", height: "800px", overflow: "auto" }}>
          <pre>
            <code>{JSON.stringify(tenant.config, null, 2)}</code>
          </pre>
        </Card>
      </AccordionWrapper>
    </div>
  )
}
