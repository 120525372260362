import React from 'react';
import { IconButton, Typography } from '@mui/material';
import CopyIcon from '@mui/icons-material/ContentCopy';
export default function CopyButton({ value }) {
  return (
    <IconButton
      size='small'
      onClick={async () => {
        navigator.clipboard.writeText(value);
      }}
    >
      <CopyIcon sx={{ fontSize: 16 }} />
    </IconButton>
  );
}
