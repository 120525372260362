import React, { useState } from "react"
import { useQuery } from "react-query"
import { fetchContent } from "src/utils/AdminApi"
// mui
import { TextField, Button, Stack, Typography, MenuItem, Select } from "@mui/material"
import { DateTime } from "luxon"
import { Link } from "react-router-dom"
import TableWrapper from "src/components/TableWrapper"
function useNewPage() {
  // example of using redux state

  const [date, setDate] = useState({
    from: DateTime.utc().startOf("month").toFormat("yyyy-MM-dd"),
    to: DateTime.utc().endOf("month").toFormat("yyyy-MM-dd"),
  })

  // console.log(date);

  const [sortBy, setSortBy] = useState("created_at")

  // // to make http request
  // let { data: admins } = useQuery(`str`, getAdmins);

  const queryResult = useQuery(
    ["new_stores", date, sortBy],
    () => fetchContent(`tenant.tenant?created_at_gte=${date.from}&created_at_lt=${date.to}&_sort=${sortBy}`),
    {
      enabled: date.from !== "" && date.to !== "",
    }
  )

  const updateMonth = (number) => {
    setDate({
      from: DateTime.fromFormat(date.from, "yyyy-MM-dd").plus({ month: number }).startOf("month").toFormat("yyyy-MM-dd"),
      to: DateTime.fromFormat(date.to, "yyyy-MM-dd").plus({ month: number }).endOf("month").toFormat("yyyy-MM-dd"),
    })
  }

  return {
    queryResult,
    sortBy,
    setSortBy,
    date,
    setDate,
    updateMonth,
  }
}

export default function NewPage() {
  const { queryResult, sortBy, setSortBy, date, setDate, updateMonth } = useNewPage()
  // console.log(test.data.data.results);
  // console.log(admins);
  const tenants = queryResult?.data?.results ?? []
  return (
    <>
      <Typography variant="h3" gutterBottom>
        متاجر حسب فترة زمنية
      </Typography>

      <Button onClick={() => updateMonth(1)}>+</Button>
      <Button onClick={() => updateMonth(-1)}>-</Button>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <TextField
          type="date"
          label="من"
          variant="outlined"
          value={date.from}
          onChange={(event) => {
            // setDate({ ...date, from: event.target.value });
            setDate({ ...date, from: event.target.value })
          }}
        />
        <TextField
          type="date"
          label="إلى"
          variant="outlined"
          value={date.to}
          onChange={(event) => {
            setDate({ ...date, to: event.target.value })
          }}
        />
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={sortBy}
          label="Sort By"
          onChange={(e) => {
            setSortBy(e.target.value)
            console.log(e.target.value)
          }}
        >
          {/* lastLoginAt:ASC or created_at:ASC */}
          <MenuItem value={"lastLoginAt"}>Last Login</MenuItem>
          <MenuItem value={"created_at"}>Date Created</MenuItem>
        </Select>
      </Stack>
      <Typography gutterBottom>عدد المتاجر = {queryResult?.data?.results?.length ?? 0}</Typography>

      <TableWrapper
        align="start"
        headerColumns={["name", "lastLoginAt", "createdAt"]}
        dataRows={tenants.map((s) => [
          <Link style={{ textDecoration: "none", color: "#0D6659" }} to={`/tenants/${s.id}`}>
            {s.site_name}
          </Link>,

          s.lastLoginAt,
          s.created_at.split("T")?.[0] ?? "",
        ])}
      />
    </>
  )
}
