import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
// material
import { Button, Card, Container, Divider, Stack, Switch, TextField, Typography } from "@mui/material"
import Page from "src/components/Page"
import { CustomAutoComplete } from "vetrina-ui"
import { useQuery } from "react-query"
import { makeHttpRequest } from "src/utils/requestBuilder"
import useStrapiAdminQuery from "src/hooks/useStrapiQuery"
import { toast } from "react-toastify"

export default function DeliveryFormTest({}) {
  const { data: countries = [] } = useQuery("countries", () => makeHttpRequest("/countries"))
  const { data: citiesResposne } = useStrapiAdminQuery({
    contentType: "cities",
    query: {
      pageSize: -1,
    },
  })
  const navigate = useNavigate()
  const [useDefault, setUseDefault] = useState(true)
  const [values, setValues] = useState({
    country: "LY",
    city: "طرابلس",
    district: "جنزور",
    total_weight: "0.5",
  })
  const [{ options, isLoading }, setOptionsState] = useState({
    isLoading: false,
    options: [],
  })
  useEffect(() => {}, [])

  return (
    <Page title="فترينا">
      <Container maxWidth="md">
        <Card sx={{ p: 3 }}>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">تجربة طرق التوصيل </Typography>
            </Stack>

            <Stack spacing={4}>
              <DeliveryForm values={values} setValues={setValues} />

              <Typography>
                اعدادات الربط الافتراضية
                <Switch checked={useDefault} onChange={(e) => setUseDefault(e.target.checked)} />
                {!useDefault && <Typography>سيتم استخدام الاعدادات الموجودة في متجر ديمو</Typography>}
              </Typography>

              <Button
                disabled={isLoading}
                onClick={async () => {
                  setOptionsState((s) => ({ ...s, isLoading: true }))
                  try {
                    const res = await makeHttpRequest(useDefault ? "getTestCityDeliveryOptions" : "/getCityDeliveryOptions", "post", {
                      country: values.country,
                      city: values.city,
                      district: values.district,
                      total_weight: values.total_weight,
                    })
                    setOptionsState((s) => ({ ...s, isLoading: false, options: res }))
                  } catch (err) {
                    toast.error(err?.response?.data?.message ?? err?.message ?? "something went wrong")
                    setOptionsState((s) => ({ ...s, isLoading: false }))
                  }
                }}
                variant="contained"
              >
                عرض طرق التوصيل المتاحة
              </Button>

              {options.map((item) => {
                return (
                  <>
                    <Stack direction="row" justifyContent="space-between" width={250} key={item.id}>
                      <Typography>{item.name}</Typography>
                      <Typography>{item.price}</Typography>
                    </Stack>
                    <Divider />
                  </>
                )
              })}

              {/* <CustomAutoComplete
                options={cities.map((c) => c.name)}
                label="المدينة"
                value={values.city}
                onChange={(e, v) => setValues({ ...values, city: v })}
              /> */}
            </Stack>
          </Stack>
        </Card>
      </Container>
    </Page>
  )
}
export function DeliveryForm({ values, setValues }) {
  const { data: countries = [] } = useQuery("countries", () => makeHttpRequest("/countries"))
  const { data: citiesResposne } = useStrapiAdminQuery({
    contentType: "cities",
    query: {
      pageSize: -1,
    },
  })

  return (
    <Stack spacing={4}>
      <CustomAutoComplete
        options={countries.map((c) => c.iso_code)}
        getOptionLabel={(o) => {
          return countries.find((c) => c.iso_code == o)?.name ?? ""
        }}
        label="الدولة"
        value={values.country}
        onChange={(e, v) => setValues({ ...values, country: v })}
      />
      <CustomAutoComplete
        options={citiesResposne?.results?.filter((c) => c.parent == null).map((c) => c.name) ?? []}
        label="المدينة"
        value={values.city}
        onChange={(e, v) => setValues({ ...values, city: v, district: "" })}
      />
      {values.city && (
        <CustomAutoComplete
          options={citiesResposne?.results?.filter((c) => c.parent && c.parent.name == values.city).map((c) => c.name) ?? []}
          label="المنطقة"
          value={values.district}
          onChange={(e, v) => setValues({ ...values, district: v })}
        />
      )}

      <Divider />

      <Typography>Testing tools</Typography>

      <Stack spacing={1} direction="row" justifyContent="space-between">
        <Button
          onClick={() => {
            setValues({
              ...values,

              country: "DE",
              city: "DRESDEN",
              district: "",
            })
            // setFieldValue('address', 'DRESDEN 01053');
            // setFieldValue('postal', '01053');
          }}
        >
          عنوان الماني
        </Button>
        <Button
          onClick={() => {
            setValues({
              ...values,
              country: "LY",
              city: "طرابلس",
              district: "جنزور",
            })
          }}
        >
          عنوان ليبي مع منطقة
        </Button>
        <Button
          onClick={() => {
            setValues({
              ...values,

              country: "LY",
              city: "المرج",
              district: "",
            })
          }}
        >
          عنوان ليبي بدون منطقة
        </Button>
      </Stack>
    </Stack>
  )
}
