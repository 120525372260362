import { DynamicForm } from "vetrina-ui"
import { showModel } from "./showModel"

export default function BasicDynamicFormModal({
  // function to close the modal
  proceed,
  inputs,
}) {
  return (
    <DynamicForm
      onSubmit={(v) => proceed(v)}
      config={{
        inputs: inputs.map((input) => {
          let name,
            label,
            placeholder,
            value = ""
          if (typeof input === "string") {
            name = label = placeholder = input
          } else {
            if (Array.isArray(input)) {
              // [name,value]
              name = label = placeholder = input[0]
              value = input[1] ?? ""
            } else {
              // object
              name = input.name
              label = input.label ?? name
              placeholder = input.placeholder ?? label ?? name
              value = input.value ?? ""
            }
          }

          return {
            name,
            label,
            placeholder,
            type: "text",
            value,
          }
        }),
      }}
    />
  )
}

export function requestUserInputs(title, inputs) {
  return showModel({
    title,
    component: <BasicDynamicFormModal inputs={inputs} />,
  })
}
