import React from "react"
import { DynamicForm } from "vetrina-ui"
import { makeHttpRequest } from "src/utils/requestBuilder"
import { toast } from "react-toastify"
import useStrapiAdminQuery from "src/hooks/useStrapiQuery"
import { Button } from "@mui/material"
import { requestUserInputs } from "src/components/BasicInputsModal"
import { strapiCreate } from "src/utils/strapiApi"

/**
 *
 * @param {object} props
 * @param {import("vetrina-types").Attributes['tenant']} props.tenant
 * @returns
 */
export default function TenantBetaFeaturesForm({ tenant }) {
  const { data, refetch } = useStrapiAdminQuery({
    contentType: "beta-features",
  })
  const features = data?.results ?? []
  return (
    <div>
      {features.length > 0 && (
        <DynamicForm
          submitButtonText="تحديث"
          config={{
            inputs: [
              ...features.map((aFeature) => {
                return {
                  label: aFeature.label,
                  name: aFeature.identifier,
                  type: "checkbox",
                  value: tenant.config?.features?.[aFeature.identifier] ?? false,
                  tip: aFeature.description,
                }
              }),
            ],
          }}
          onSubmit={async (v) => {
            try {
              await makeHttpRequest("/tenants/" + tenant.id, "put", {
                config: {
                  ...tenant.config,
                  features: {
                    ...(tenant.config?.features ?? {}),
                    ...v,
                  },
                },
              })
              toast.success("updated successfully")
            } catch (err) {
              toast.error("error occur, check logs")
              console.log(err)
            }
          }}
        />
      )}

      <Button
        sx={{ mt: 4 }}
        variant="outlined"
        onClick={async () => {
          const values = await requestUserInputs("Define new beta feature", ["label", "identifier"])
          await strapiCreate("beta-features", values)
          toast.success("created successfully")
          refetch()
        }}
      >
        Define new beta feature
      </Button>
    </div>
  )
}
