import { UseQueryResult, useQuery, QueryKey } from "react-query"
import { IStrapiFindManyQuery, strapiAdminFindMany } from "src/utils/strapiAdminApi"
import { strapiFindMany } from "src/utils/strapiApi"
import { Attributes, ContentType, ContentTypeAttributes } from "vetrina-types"

interface IUseStrapiQuery<T extends ContentType> {
  key: QueryKey
  contentType: T
  query?: object
}
export default function useStrapiAdminQuery<T extends ContentType>({
  contentType,
  key = contentType,
  query = {},
}: IUseStrapiQuery<T>): UseQueryResult<IStrapiFindManyQuery<T>, unknown> {
  const res = useQuery<IStrapiFindManyQuery<T>, unknown>(key, () => strapiAdminFindMany(contentType, query), {})

  return res
}

interface IUseStrapiQuery<T extends ContentType> {
  key: QueryKey
  contentType: T
}
export function useStrapiApiQuery<T extends ContentType>({ contentType, key = contentType }: IUseStrapiQuery<T>) {
  const res = useQuery<Array<ContentTypeAttributes<T>>, unknown>(key, () => strapiFindMany(contentType), {})

  return res
}
