import { Typography } from "@mui/material"
import React from "react"
import { useQuery } from "react-query"
import { toast } from "react-toastify"
import { LoadingView } from "src/components/LoadingView"
import { makeHttpRequest } from "src/utils/requestBuilder"
import { DynamicForm } from "vetrina-ui"
import * as yup from "yup"

export default function ShippingProviderAuthForm({ provider }) {
  const { data: providersTestConfig = {}, testConfigIsLoading } = useQuery("getProvidersTestConfig", () => makeHttpRequest("/getProvidersTestConfig"))
  const { data: shippingProviders = [], isLoading } = useShippingProviders()
  const providerConfig = shippingProviders?.find((p) => p.id === provider)
  const testproviderConfig = providersTestConfig[provider]

  if (isLoading || testConfigIsLoading) return <LoadingView />
  const formConfig = providerConfig.setupForm
    ? {
        inputs: providerConfig.setupForm.inputs.map((input) => {
          return {
            ...input,
            // if type is password don't set the value
            value: testproviderConfig?.credentials[input.name] ?? "",
            validation: input.type === "password" ? yup.string().required("يرجي ادخال كلمة المرور") : yup.string().required("هذا الحقل اجباري"),
          }
        }),
      }
    : getAccountSetupForm(testproviderConfig.credentials)

  return (
    <div>
      <Typography>تجربة التحقق</Typography>
      <DynamicForm
        submitButtonText="authenticate"
        config={formConfig}
        onSubmit={async (credentials) => {
          try {
            const res = await makeHttpRequest(`/shipping/${provider}/getAccessToken`, "post", {
              credentials,
            })
            toast.success("success")
          } catch (err) {
            toast.error("fail")
          }
        }}
      />
    </div>
  )
}

const useShippingProviders = (options = {}) => {
  return useQuery("getshippingproviders", () => makeHttpRequest("/shipping/providers"), {
    refetchOnWindowFocus: false,
    ...options,
  })
}

const getAccountSetupForm = (credentials) => {
  return {
    inputs: [
      {
        label: "البريد الالكتروني",
        placeholder: "البريد الالكتروني",
        name: "email",
        type: "text",
        validation: yup.string().required("يرجي ادخال البريد الالكتروني"),
        value: credentials?.email ?? "",
      },
      {
        label: "كلمة المرور",
        placeholder: "كلمة المرور",
        name: "password",
        type: "password",
        validation: yup.string().required("يرجي ادخال كلمة المرور"),
        value: credentials?.password ?? "",
      },
    ],
  }
}
