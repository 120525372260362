//Create dataStorage
const saveDataToStorage = (name, data) => {
  localStorage.setItem(name, data)
}

const getDataFromStorage = (name) => {
  return localStorage.getItem(name)
}

const admin_token_key = "@vetrina_admin_token"
export const storeAdminToken = (token) => saveDataToStorage(admin_token_key, token)
export const getAdminToken = () => getDataFromStorage(admin_token_key) ?? ""

export const adminIsLoggedIn = () => getDataFromStorage(admin_token_key)?.length > 0 ?? false
export const removeAdminToken = () => localStorage.removeItem(admin_token_key)
