const { toast } = require('react-toastify');

/* 
    usage
    const res = await handlePromise(somePromise) // will toast automatically
    doSomething(res) //✅ will go to this line if success

    const res = await handlePromise(somePromise) // will toast error automatically
    doSomething(res) //❌  will not go to this line if error
*/
export const handlePromise = async (promise, shouldThrow = true) => {
  try {
    const res = await promise;
    toast.success('تمت العملية بنجاح');
    return res;
  } catch (error) {
    toast.error('حدث خطأ غير متوقع');
    console.log(error);
    if (shouldThrow) throw error;
  }
};
