import React, {useEffect} from 'react';
import * as serviceWorker from './serviceWorker';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

const ServiceWorkerWrapper = () => {
	const [showReload, setShowReload] = React.useState(false);

	const onSWUpdate = (registration) => {
		setShowReload(true);
	};

	useEffect(() => {
		serviceWorker.register({onUpdate: onSWUpdate});
		// try to install new service worker if available
		setTimeout(() => {
			tryToInstallNewSW();
		}, 1000);

		// Add event listener for visibility change
		document.addEventListener('visibilitychange', () => {
			if (!document.hidden) {
				tryToInstallNewSW();
			}
			// console.log('visibility', document.hidden);
		});

		// Cleanup: Remove the event listener when the component unmounts
		return () => {
			document.removeEventListener('visibilitychange', tryToInstallNewSW);
		};
	}, []);

	const tryToInstallNewSW = () => {
		navigator.serviceWorker.ready.then(
			(reg) => reg.update(),
			(error) => console.log('error', error),
		);
	};
	const reloadPageClicked = () => {
		setShowReload(false);
		window.location.reload(true);
	};

	return (
		<Snackbar
			open={showReload}
			message="تحديثات جديدة متوفرة !!"
			onClick={reloadPageClicked}
			anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
			action={
				<Button color="primary" variant="contained" size="small" onClick={reloadPageClicked}>
					تحديث
				</Button>
			}
		/>
	);
};

export default ServiceWorkerWrapper;
