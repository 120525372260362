import { Attributes, ContentType, ContentTypeAttributes } from "vetrina-types"
import { makeHttpRequest } from "./requestBuilder"
import qs from "qs"
import { isNullOrEmpty } from "./helperMethods"

export interface IStrapiFindManyQuery<T extends ContentType> {
  results: Array<ContentTypeAttributes<T>>
  pagination: {
    page: number
    pageSize: number
    pageCount: number
    total: number
  }
}
export function strapiAdminFindMany<T extends ContentType>(contentType: T, query?: object): Promise<IStrapiFindManyQuery<T>> {
  let querystring = ""
  if (!isNullOrEmpty(query)) {
    querystring = "?" + qs.stringify(query)
  }
  //@ts-ignore
  return makeHttpRequest(`/content-manager/collection-types/application::${contentType}.${contentType}${querystring}`)
}

export function strapiAdminFindOne<T extends ContentType>(contentType: T, id: number | string): Promise<ContentTypeAttributes<T>> {
  //@ts-ignore
  return makeHttpRequest(`/content-manager/collection-types/application::${contentType}.${contentType}/${id}`)
}

export function strapiAdminDelete<T extends ContentType>(contentType: T, id: number | string): Promise<ContentTypeAttributes<T>> {
  //@ts-ignore
  return makeHttpRequest(`/content-manager/collection-types/application::${contentType}.${contentType}/${id}`, "delete")
}

export function strapiAdminUpdate<T extends ContentType>(
  contentType: T,
  id: number | string,
  updates: Partial<ContentTypeAttributes<T>>
): Promise<ContentTypeAttributes<T>> {
  //@ts-ignore
  return makeHttpRequest(`/content-manager/collection-types/application::${contentType}.${contentType}/${id}`, "put", updates)
}
export function strapiAdminCreate<T extends ContentType>(contentType: T, data: Partial<ContentTypeAttributes<T>>): Promise<ContentTypeAttributes<T>> {
  //@ts-ignore
  return makeHttpRequest(`/content-manager/collection-types/application::${contentType}.${contentType}`, "post", data)
}
