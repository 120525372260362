import { Button, Container, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import DateFilter from "src/components/DateFilter";
import { fetchContent } from "src/utils/AdminApi";
import DailyLoginStats from "./components/DailyLoginStats";

export default function StatsPage() {
  const [stats, setStats] = useState();
  const [isLoading, setIsloading] = useState(false);
  const {
    delivery,
    pickup,
    fromStore,
    fromCms,
    darb,
    topSales,
    vanex,
    cities,
    districts,
  } = stats ?? {};
  const fetch = async () => {
    setIsloading(true);
    let delivery = 0;
    let pickup = 0;
    let fromStore = 0;
    let fromCms = 0;
    let darb = 0;
    let vanex = 0;
    let topSales = {};
    let cities = {};
    let districts = {};
    let whoUseVanex = new Set();

    const { results: orders } = await fetchContent(
      `guest-orders.guest-orders?pageSize=-1&_sort=id:DESC`
    );
    orders.forEach((order) => {
      if (order.guestId) {
        fromStore++;
      } else {
        fromCms++;
      }

      const site = order.TenantID?.site_name;
      if (topSales[site]) {
        topSales[site] = topSales[site] + 1;
      } else {
        topSales[site] = 1;
      }

      if (order.delivery_info) {
        if (order.delivery_info?.type === "pickup") {
          pickup++;
        } else {
          delivery++;
          if (order.delivery_info.shippingProvider === "darb") darb++;
          else if (order.delivery_info.shippingProvider === "vanex") {
            vanex++;
            whoUseVanex.add(order.TenantID.host);
          }

          const orderCity = order.delivery_info.city;
          if (cities[orderCity]) {
            cities[orderCity] = cities[orderCity] + 1;
          } else {
            cities[orderCity] = 1;
          }
          const orderDistrict = order.delivery_info.district;
          if (orderDistrict) {
            if (districts[orderCity]?.[orderDistrict]) {
              districts[orderCity][orderDistrict] =
                districts[orderCity][orderDistrict] + 1;
            } else {
              districts[orderCity] = {
                ...districts[orderCity],
                [orderDistrict]: 1,
              };
            }
          }
        }
      }
    });

    setStats({
      topSales,
      delivery,
      pickup,
      fromStore,
      fromCms,
      darb,
      vanex,
      cities,
      districts,
    });
    setIsloading(false);
  };
  // useEffect(() => {
  //   fetch();
  // }, []);

  if (isLoading) {
    return (
      <Stack>
        <p>loading... </p>
        <p>this may take some time</p>
      </Stack>
    );
  }

  if (stats) {
    return (
      <Container>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <h1>Statistics based on orders</h1>
            <p>
              <strong>{fromCms}</strong> cms <strong>{fromStore}</strong>{" "}
              storefront
            </p>
            <p>
              <strong>{darb}</strong> darb <strong>{vanex}</strong> vanex{" "}
            </p>
            <p>
              <strong>{delivery}</strong>
              delivery <strong>{pickup}</strong> pickup
            </p>
          </Stack>
          <h3>number of orders for each site</h3>
          {Object.keys(topSales)
            .sort((a, b) => topSales[b] - topSales[a])
            .slice(0, 10)
            .map((site) => (
              <Stack>
                <p>
                  {site}: {topSales[site]}
                </p>
              </Stack>
            ))}
          <h3>Cities orders count</h3>
          {Object.keys(cities)
            .sort((a, b) => cities[b] - cities[a])
            .slice(0, 10)
            .map((city) => (
              <Stack>
                <p>
                  {city}: {cities[city]}
                </p>
                {/* <p>districts</p>
              {districts[city] &&
                Object.keys(districts)
                  .sort((a, b) => districts[b] - districts[a])
                  .slice(0, 10)
                  .map((district) => (
                    <p>
                      {district}: {districts[city][district]}
                    </p>
                  ))} */}
              </Stack>
            ))}
        </Stack>
      </Container>
    );
  }

  return (
    <Container>
      <DailyLoginStats />
      <Button variant="contained" sx={{ mt: 2 }} onClick={fetch}>
        generate advanced statistics
      </Button>
    </Container>
  );
}
