import { useState } from "react"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
// material
import { Autocomplete, Box, Button, Card, CircularProgress, Container, Stack, TextField, Typography } from "@mui/material"
import { useQuery } from "react-query"
import { toast } from "react-toastify"
import { requestUserInputs } from "src/components/BasicInputsModal"
import Page from "src/components/Page"
import { fetchContent } from "src/utils/AdminApi"
import { isNullOrEmpty } from "src/utils/helperMethods"
import { makeHttpRequest } from "src/utils/requestBuilder"
import searchForBestMatch from "src/utils/searchForBestMatch"
import { getMatchedCity } from "src/utils/searchForMatchedCity"
import { CheckboxWrapper } from "vetrina-ui"
import ShippingProviderAuthForm from "./ShippingProviderAuthForm"
import AccordionWrapper from "src/components/AccordionWrapper"
import TestCreateShipment from "./TestCreateShipment"
export default function AnyProviderMapping({}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const { provider } = useParams()
  const cityId = searchParams.get("cityId")

  const { data: cities = [], citiesIsLoading } = useQuery("all-cities", () => makeHttpRequest("/cities?_limit=-1"), {
    refetchOnWindowFocus: false,
  })

  const {
    data: citiesMapping,
    isLoading,
    refetch: refetchCitiesMapping,
  } = useQuery(["cities-mapping", provider], () => fetchContent(`cities-mapping.cities-mapping?provider=${provider}&pageSize=-1`), {
    refetchOnWindowFocus: false,
  })
  const { data: providerCities = [], providerCitiesIsLoading } = useQuery(
    ["provider-cities", provider],
    () => makeHttpRequest(`/getProviderCities?provider=${provider}`),
    {
      enabled: provider != null,
      refetchOnWindowFocus: false,
    }
  )

  const vetrinaCities = cities?.filter((c) => {
    if (cityId != null) {
      return c.parent?.id == cityId
    } else {
      return c.parent == null
    }
  })
  const [results, setResults] = useState([])

  const getMappedCity = (vetrinaCity) => {
    return citiesMapping?.results?.find((c) => c.city.id == vetrinaCity.id)
  }

  const autoMap = async () => {
    if (!window.confirm("are you sure you want to auto map?")) return

    const providerCities_needs_mapping = providerCities?.filter((providerCity) => {
      let mappedCity = citiesMapping?.results?.find((c) => c.map_name == providerCity.name)
      return mappedCity == null
    })

    console.log("providerCities_needs_mapping.length", providerCities_needs_mapping.length)
    if (providerCities_needs_mapping.length === 0) return alert("nothing to map ")

    for (let providerCity of providerCities_needs_mapping) {
      const matched_city = getMatchedCity(providerCity.name, vetrinaCities, "name", 0.7)
      if (!matched_city) {
        console.log("no match found", providerCity.name)
        continue
      }

      try {
        await makeHttpRequest("/cities-mappings", "post", {
          provider,
          city: matched_city.id,
          map_id: `${providerCity.id}`,
          map_name: `${providerCity.name}`,
        })
      } catch (err) {}
    }
  }

  const testMapping = async (c, d) => {
    let { delay, start, limit } = await requestUserInputs("sleep time", [
      ["delay", 0],
      ["start", 0],
      ["limit", ""],
    ])
    if (!limit) limit = vetrinaCities.length

    for (let i = +start; i < Math.min(limit, vetrinaCities.length); i++) {
      const c = vetrinaCities[i]
      const city = c.parent ? c.parent : c
      const district = c.parent ? c : undefined

      if (!getMappedCity(c)) {
        console.log("no mapping found", c.name, c.id)
        continue
      }

      let url = `/shipping/${provider}/price?city=${city.name}`
      if (district) url += `&district=${district.name}`

      try {
        const res = await makeHttpRequest(url)

        setResults((r) => [
          ...r,
          {
            name: district?.name ?? city.name,
            ...res,
          },
        ])

        if (delay > 0) await new Promise((r) => setTimeout(r, delay))
      } catch (err) {
        setResults((r) => [
          ...r,
          {
            name: district?.name ?? city.name,
            success: false,
          },
        ])
      }
    }
  }

  if (providerCitiesIsLoading || isLoading) return "loading"
  return (
    <Page title="فترينا">
      <Container>
        <Stack spacing={2}>
          <AccordionWrapper card parent={"authentication"}>
            <Card sx={{ p: 2 }}>
              <ShippingProviderAuthForm provider={provider} />
            </Card>
          </AccordionWrapper>
          <AccordionWrapper card parent={"test create shipment"}>
            <Card sx={{ p: 2 }}>
              <TestCreateShipment provider={provider} />
            </Card>
          </AccordionWrapper>
        </Stack>

        <Card sx={{ p: 3, mt: 4 }}>
          <Stack spacing={2}>
            <Typography variant="h2">{provider} </Typography>
            <Typography variant="h4">المدن </Typography>

            <Stack spacing={1} direction="row" justifyContent="space-between">
              {/* <Button onClick={autoMap}>auto map</Button> */}
              <Button onClick={testMapping}>test mapping</Button>
            </Stack>
            <Stack spacing={4}>
              {vetrinaCities.map((vetrinaCity, idx) => {
                let mappedCity = getMappedCity(vetrinaCity)
                let mapTestResult = results?.find((c) => c.name == vetrinaCity.name)
                const parentCityId = cityId ? citiesMapping?.results?.find((c) => c.city.id == cityId)?.map_id : null
                return (
                  <CityRow
                    providerCities={providerCities}
                    index={idx}
                    city={vetrinaCity}
                    mappedCity={mappedCity}
                    result={mapTestResult}
                    parentCityId={parentCityId}
                    refetchCitiesMapping={refetchCitiesMapping}
                  />
                )
              })}
            </Stack>
          </Stack>
        </Card>

        <Box my={14}>
          <ProviderDropdown />
        </Box>
      </Container>
    </Page>
  )
}

/**
 *
 * @param {object} param0
 * @param {import("vetrina-types").Attributes['cities-mapping']} param0.mappedCity
 * @param {import("vetrina-types").Attributes['cities']} param0.city
 * @returns
 */
const CityRow = ({ city, mappedCity, index, result, parentCityId, providerCities, refetchCitiesMapping }) => {
  const { provider } = useParams()
  const path = useLocation().pathname
  const navigate = useNavigate()

  const { data: providerDistricts = [] } = useQuery(
    ["provider-districts", parentCityId],
    () => makeHttpRequest(`/getProviderDistricts?cityId=${parentCityId}&provider=${provider}`),
    {
      refetchOnWindowFocus: false,
      enabled: !!parentCityId,
    }
  )

  const options = parentCityId ? [...providerDistricts, ...providerCities.map((c) => ({ ...c, isMain: true }))] : providerCities

  const [selectedValue, setSelectedValue] = useState(mappedCity?.map_name ?? "")
  const [asParent, setAsParent] = useState(mappedCity?.as_parent ?? false)
  const [loading, setLoading] = useState(false)

  const didnotChange = (mappedCity?.map_name ?? "") == selectedValue && (mappedCity?.as_parent ?? false) == asParent
  // const [updatedValue, setUpdateValue] = useState(selectedValue)

  const handleRemove = async () => {
    if (window.confirm("are u sure?")) {
      setLoading(true)
      await makeHttpRequest(`/cities-mappings/${mappedCity.id}`, "delete")
      setSelectedValue("")
      refetchCitiesMapping()

      setLoading(false)
      return toast.success("removed")
    }
  }

  return (
    <Stack spacing={2}>
      <Stack>
        <Stack sx={{ color: result?.success === false && "red" }} spacing={2} direction="row" alignItems="center" key={city.id}>
          <Typography width={30}>{index + 1} </Typography>
          <Box width={150}>
            {isNullOrEmpty(city.districts) ? (
              <Typography>{city.name} </Typography>
            ) : (
              <Button href={`${path}?cityId=${city.id}`}>
                <Typography>{city.name} </Typography>
              </Button>
            )}
          </Box>

          {/* <Typography width={30}>{city.id} </Typography> */}
          {/* <Typography width={100}>{mappedCity ? mappedCity.city.name : "none"} </Typography> */}

          {/* <Button
            sx={{ width: 250 }}
            onClick={async () => {
              const name = prompt("enter city name")
    
              makeHttpRequest("/cities", "post", {
                name: name,
              })
    
              toast.success("city created")
              refetchCities()
            }}
            size="small"
            variant="contained"
          >
            add new city
          </Button> */}
          <Autocomplete
            size="small"
            sx={{ width: 250 }}
            value={selectedValue}
            onChange={(e, n) => setSelectedValue(n?.label ?? "")}
            // TODO we need to map
            options={options.map((r) => ({ ...r, label: r.name, value: r.id }))}
            renderInput={(params) => <TextField width={250} {...params} />}
            isOptionEqualToValue={(option, value) => {
              // console.log(option, value)
              return option.label == selectedValue
            }}
            getOptionLabel={(value) => {
              if (typeof value === "object") {
                const isMain = value.isMain ? "# " : ""
                return `${isMain}${value.label} ${value.value}`
              } else {
                return value
              }
            }}
          />
          {/* {selectedValue} */}

          {/* <Button
            onClick={async () => {
              const matched_city = getMatchedCity(city.name, providerCities, "name", 0.7)
              if (!matched_city) return alert("no match found")
              makeHttpRequest("/cities-mappings", "post", {
                provider: "skyex",
                city: city.id,
                map_id: `${matched_city.id}`,
                map_name: `${matched_city.name}`,
              })
    
              toast.success("mapping created")
    
              setUpdateValue(matched_city.name)
            }}
            size="small"
            variant="contained"
          >
            auto
          </Button> */}

          <Stack spacing={1} flex={1} direction="row" justifyContent="space-between">
            <CheckboxWrapper label={"as parent"} value={asParent} onChange={(e) => setAsParent(e.target.checked)} />
            <Button
              onClick={() => {
                const res = searchForBestMatch(city.name, options, "name")
                if (!res?.name) return toast.error("no match found")
                setSelectedValue(res.name)
              }}
            >
              map
            </Button>

            <Button
              onClick={async () => {
                const values = await requestUserInputs("Mapping details", [
                  ["map_id", mappedCity.map_id],
                  ["map_name", mappedCity.map_name],
                  ["map_parent_id", mappedCity.map_parent_id],
                  ["map_parent_name", mappedCity.map_parent_name],
                ])
                console.log("🚀 ~ CityRow ~ values:", values)
                values.provider = provider
                values.city = city.id

                if (mappedCity) {
                  await makeHttpRequest(`/cities-mappings/${mappedCity.id}`, "put", values)
                  toast.success("updated")
                } else {
                  await makeHttpRequest("/cities-mappings", "post", values)
                  toast.success("mapping created")
                }
                refetchCitiesMapping()
              }}
            >
              manually
            </Button>
            <Button
              disabled={didnotChange || loading}
              onClick={async () => {
                if (mappedCity && !selectedValue) {
                  return handleRemove()
                }
                setLoading(true)
                const selectedProviderCity = options.find((c) => c.name === selectedValue)
                const input = {
                  provider,
                  city: city.id,
                  // TODO
                  map_id: `${selectedProviderCity.id}`,
                  map_name: `${selectedProviderCity.name}`,
                  as_parent: asParent ?? false,
                }

                if (mappedCity) {
                  await makeHttpRequest(`/cities-mappings/${mappedCity.id}`, "put", input)
                  toast.success("updated")
                } else {
                  await makeHttpRequest("/cities-mappings", "post", input)
                  toast.success("mapping created")
                }
                setLoading(false)
                refetchCitiesMapping()

                // setUpdateValue(selectedValue)
              }}
              size="small"
              variant="contained"
            >
              Confirm
            </Button>

            <Button color="error" disabled={mappedCity == null} onClick={handleRemove}>
              delete
            </Button>

            {/* TODO  */}
            <button
              onClick={async () => {
                const c = city.parent ? city.parent : city
                const d = city.parent ? city : undefined

                let url = `/shipping/${provider}/price?city=${c.name}`
                if (d) url += `&district=${d.name}`
                try {
                  const res = await makeHttpRequest(url)
                  alert(`price=${res.price}`)
                } catch (err) {
                  alert("fail")
                }
              }}
            >
              price
            </button>
          </Stack>
        </Stack>

        {mappedCity?.map_parent_id && (
          <Typography variant="caption" align="center">
            {mappedCity.map_parent_id} {mappedCity.map_parent_name} {"->"} {mappedCity.map_id} {mappedCity.map_name}
          </Typography>
        )}
      </Stack>

      {result && (
        <Stack sx={{ color: result.success ? "green" : "red" }} spacing={3} direction="row">
          <Typography>السعر: {result.price}</Typography>
          <Typography>
            {/* TODO: key to access meta, stay the same? */}
            المدينة: {result.meta?.address?.state_name} {result.meta?.address?.state_id}
          </Typography>
          <Typography>
            المنطقة: {result.meta?.address?.place_name} {result.meta?.address?.place_id}
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

// used to test based on their cities/district structure, in case there's a problem
const ProviderDropdown = () => {
  const { provider } = useParams()

  const { data: providerCities = [], providerCitiesIsLoading } = useQuery(
    ["provider-cities", provider],
    () => makeHttpRequest(`/getProviderCities?provider=${provider}`),
    {
      refetchOnWindowFocus: false,
    }
  )

  const [selectedCity, setSelectedCity] = useState("")
  const [selectedDistrict, setSelectedDistrict] = useState("")

  const { data: providerDistricts = [], isLoading: providerDistrictsIsLoading } = useQuery(
    ["provider-districts", provider, selectedCity],
    () => makeHttpRequest(`/getProviderDistricts?provider=${provider}&cityId=${selectedCity}`),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedCity,
      onSuccess: (d) => {
        console.log("d", d)
        setSelectedDistrict(d[0]?.id ?? "")
      },
    }
  )

  if (providerCitiesIsLoading) return "loading"

  return (
    <div>
      <Typography>Provider API debugging</Typography>
      <Stack spacing={1} direction="row" justifyContent="space-between">
        <Autocomplete
          size="small"
          fullWidth
          value={providerCities.find((c) => c.id == selectedCity)?.name ?? ""}
          onChange={(e, n) => setSelectedCity(n?.value)}
          options={providerCities.map((r) => ({ label: r.name, value: r.id }))}
          renderInput={(params) => <TextField width={250} {...params} />}
          isOptionEqualToValue={(option, value) => {
            // console.log(option, value)
            return option.value == selectedCity
          }}
        />
        <Autocomplete
          size="small"
          fullWidth
          value={providerDistricts.find((c) => c.id == selectedDistrict)?.name ?? ""}
          onChange={(e, n) => setSelectedDistrict(n?.value ?? "")}
          options={providerDistricts.map((r) => ({ label: r.name, value: r.id }))}
          renderInput={(params) => <TextField width={250} {...params} />}
          isOptionEqualToValue={(option, value) => {
            // console.log(option, value)
            return option.value == selectedDistrict
          }}
        />
        {providerDistrictsIsLoading && <CircularProgress />}
      </Stack>

      <Stack mt={2} spacing={3} direction="row">
        <p>city id: {selectedCity}</p>
        <p>district id: {selectedDistrict}</p>
      </Stack>
      <Button
        variant="contained"
        onClick={async () => {
          let url = `/getShippingPriceWithoutMapping?provider=${provider}&city=${selectedCity}&district=${selectedDistrict}`

          try {
            const res = await makeHttpRequest(url)
            alert(`price=${res.price}`)
          } catch (err) {
            alert("fail")
          }
        }}
      >
        get price
      </Button>
    </div>
  )
}

// TODO: show the parent city
// TODO add functionality to calculate price
