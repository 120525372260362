import { Button, Container, Stack, TextField, useMediaQuery, Select, MenuItem, FormControl, InputLabel } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { Link, useSearchParams } from "react-router-dom"
import DeleteAlertDialog from "src/components/DeleteAlertDialog"
import { LoadingView } from "src/components/LoadingView"
import TableWrapper from "src/components/TableWrapper"
import { useDebounce } from "src/hooks/useDebounce"
import { deleteContent, fetchContent } from "src/utils/AdminApi"
import { strapiAdminFindMany } from "src/utils/strapiAdminApi"

function useFindTenants() {
  const [searchBoxValue, setSearchBoxValue] = React.useState("")
  const debouncedSearchValue = useDebounce(searchBoxValue, 500)
  const [sortParams, setSortParams] = useSearchParams({
    sortBy: "created_at:DESC",
  })
  const sortByValue = sortParams.get("sortBy")

  const { data: res, isLoading } = useQuery(
    ["tenants", debouncedSearchValue, sortByValue],
    () =>
      strapiAdminFindMany("tenant", {
        _q: debouncedSearchValue,
        _sort: sortByValue,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )
  const tenants = res?.results ?? []
  const isLarge = useMediaQuery("(min-width:600px)")

  const handleDelete = async (id) => {
    try {
      const res = await deleteContent("tenant.tenant", id)
      // setData(Data.filter((a) => a.id !== id));
    } catch (error) {
      alert("something went wrong")
    }
  }

  const onClearButtonClicked = () => {
    setSearchBoxValue("")
  }

  return {
    searchValue: searchBoxValue,
    setSearchValue: setSearchBoxValue,
    isLoading,
    tenants,
    handleDelete,
    onClearButtonClicked,
    sortParams,
    sortByValue,
    setSortParams,
  }
}
export default function TenantsPage() {
  const {
    searchValue,
    setSearchValue,
    isLoading,
    tenants = [],
    handleDelete,
    onClearButtonClicked,
    sortParams,
    sortByValue,
    setSortParams,
  } = useFindTenants()

  return (
    <Container>
      <Stack spacing={3} alignItems="center" direction="row" justifyContent="space-between">
        <h1>المتاجر</h1>
        <Stack alignItems="center" spacing={1} direction="row">
          <FormControl fullWidth>
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortByValue}
              label="Sort By"
              onChange={(e) => {
                setSortParams({ sortBy: e.target.value })
                // setSortBy(e.target.value);
              }}
            >
              {/* lastLoginAt:ASC or created_at:ASC */}
              <MenuItem value={"lastLoginAt:DESC"}>Last Login</MenuItem>
              <MenuItem value={"created_at:DESC"}>Date Created</MenuItem>
            </Select>
          </FormControl>
          <TextField value={searchValue} onChange={(e) => setSearchValue(e.target.value)} fullWidth name="q" placeholder="البحث" type="text" />
          <Button onClick={onClearButtonClicked}>clear</Button>
        </Stack>
      </Stack>

      {isLoading === true && tenants.length === 0 && <LoadingView />}

      <TableWrapper
        align="start"
        headerColumns={["name", "logo", "lastLoginAt", "createdAt", "delete"]}
        dataRows={tenants.map((s) => [
          <Link style={{ textDecoration: "none", color: "#0D6659" }} to={`/tenants/${s.id}`}>
            {s.site_name}
          </Link>,
          <img alt="logo" key={s.id} width={30} height={30} src={s.logo?.url} />,
          s.lastLoginAt,
          s.created_at.split("T")?.[0] ?? "",
          <DeleteAlertDialog onDelete={() => handleDelete(s.id)} />,
        ])}
      />

      {/* {Data.map((item) => {
        return (
          <div key={item.id}>
            <Link to={`/tenants/${item.id}`}>{item.site_name}</Link>
          </div>
        );
      })} */}
    </Container>
  )
}
