// material
import { experimentalStyled as styled } from "@mui/material/styles"
import { Card, Container, Typography, Button } from "@mui/material"
// layouts
import AuthLayout from "../layouts/AuthLayout"
// components
import Page from "../components/Page"
import { AdminLoginForm } from "../components/authentication/login"
import { Link } from "react-router-dom"
import { adminForgetPasswordLink } from "src/config"

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  // paddingRight:150,
  padding: theme.spacing(10, 10),
}))

const SectionStyle = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 600,
  display: "flex",
  flexDirection: "column",
  // justifyContent: 'center',
}))

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  // padding: theme.spacing(12, 0)
}))

const SwitchTabs = styled("div")(({ theme }) => ({
  top: 0,
  left: "50%",
  transform: "translateX(-50%)",
  position: "absolute",
  display: "flex",
  gap: 16,
}))

const TabButton = styled(Button)(({ theme }) => ({
  minWidth: 100,
  minHeight: 60,
}))

// ----------------------------------------------------------------------

export default function AdminLogin() {
  return (
    <RootStyle title="Welcome">
      <Container maxWidth="sm">
        <ContentStyle>
          {/* <AuthSocial /> */}
          <Card sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>
              تسجيل الدخول
            </Typography>

            {<AdminLoginForm />}
          </Card>
          <a style={{ color: "gray", marginTop: 8, textAlign: "center" }} href={adminForgetPasswordLink}>
            نسيت كلمة المرور ؟
          </a>
        </ContentStyle>
      </Container>

      {/* <MHidden width="mdDown"> */}

      {/* </MHidden> */}
    </RootStyle>
  )
}
