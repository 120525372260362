import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import { getAdminUserInfo, adminLogin, updateAdminUserInfo } from "src/utils/AdminApi"
import { getAdminToken, removeAdminToken, storeAdminToken } from "src/utils/storage"

export default function useUser() {
  const navigate = useNavigate()
  const {
    data: user,
    isLoading,
    error,
    refetch: refetchUser,
  } = useQuery(
    "userprofile",
    async () => {
      const token = getAdminToken()
      if (!token) return null
      try {
        const response = await getAdminUserInfo(token)
        return response.data
      } catch (err) {
        logout()
        navigate("/login")
      }
    },
    {
      refetchOnMount: false,
    }
  )

  const login = async (email, password) => {
    const response = await adminLogin(email, password)
    const { user, token } = response.data
    storeAdminToken(token)
    refetchUser()
  }

  const updateUser = async (user) => {
    const res = await updateAdminUserInfo(user)
    refetchUser()
  }

  const logout = () => {
    removeAdminToken()
    refetchUser()
  }

  return {
    user,
    isLoading,
    error,
    refetchUser,
    login,
    logout,
    updateUser,
    isLoggedIn: user != null,
    isAdmin: user && user.roles[0].id === 1,
  }
}
