import PlusFill from "@iconify/icons-eva/plus-fill"
import { Icon } from "@iconify/react"
import { Button, Checkbox, Container, Drawer, FormControlLabel, Stack, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { useQuery } from "react-query"
import DeleteAlertDialog from "src/components/DeleteAlertDialog.js"
import FormikWrapper from "src/components/FormikWrapper"
import Page from "src/components/Page"
import SubmitButton from "src/components/SubmitButton"
import TableWrapper from "src/components/TableWrapper"
import useUser from "src/hooks/useUser"
import { addAdmin, deleteAdmin, getAdmins, updateAdmin } from "src/utils/AdminApi"
import { updateArray } from "src/utils/helperMethods"
import * as Yup from "yup"

const charactersCount = 8
const charsRegex = /(?=.*\d).{8,}/gm
//
const upperCaseRegex = /[A-Z]+/gm
const numberRegex = /[1-9]+/gm
const schema = Yup.object().shape({
  firstname: Yup.string().min(2).required("first name is required"),
  lastname: Yup.string().min(2).required("last name is required"),
  email: Yup.string().email("enter valid email").required("email is required"),
  password: Yup.string()
    .required("Please Enter your password")
    // we wrap (?=.*) [a-z] and other pattern to match without order
    // we change .{5,} outside to match 5 characters in the full text not single word 5 with letters count
    // .matches(
    //   '(?=.*[A-Z]+)(?=.*[1-9]+)(?=.*[a-z]).{5,}',
    //   'Must Contain at least 5 Characters, One Uppercase, One Lowercase, One Number'
    // )
    .matches(charsRegex, "")
    .matches(numberRegex, "")
    .matches(upperCaseRegex, ""),
})
const updateSchema = Yup.object().shape({
  firstname: Yup.string().min(2).required("first name is required"),
  lastname: Yup.string().min(2).required("last name is required"),
  email: Yup.string().email("enter valid email").required("email is required"),
})

export function useAdmins(defaultValue = []) {
  const { data: admins = defaultValue } = useQuery(
    "admins",
    async () => {
      const res = await getAdmins()
      return res.data.results
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 100000,
    }
  )

  return {
    admins,
  }
}
export default function Admins() {
  const [createDrawerIsVisible, setCreateDrawerVisiblity] = useState(false)
  const [adminToEdit, setAdminToEdit] = useState()
  const { logout, user, isAdmin } = useUser()
  const { admins, setAdmins } = useAdmins()

  const renderAdmins = () => {
    const rows = admins.map((admin) => [
      admin.firstname,
      admin.lastname,
      admin.email,
      <Button
        onClick={() => {
          setAdminToEdit(admin)
        }}
        variant="text"
      >
        تعديل
      </Button>,
      <DeleteAlertDialog onDelete={() => handleDelete(admin.id)} />,
    ])
    return <TableWrapper dataRows={rows} />
  }

  const handleCreate = async (values, actions) => {
    try {
      const { password, isAdmin, ...newAdmin } = values
      const res = await addAdmin(newAdmin, isAdmin ? 1 : 2)

      const updateRes = await updateAdmin(res.data.id, { password })
      actions.resetForm()
      setCreateDrawerVisiblity(false)
      setAdmins([...admins, res.data])
    } catch (error) {
      alert(error.message)
    }
  }

  const handleUpdate = async (values, actions) => {
    try {
      const { isAdmin, ...input } = values
      input.roles = [isAdmin ? 1 : 2]
      const res = await updateAdmin(adminToEdit.id, input)
      actions.resetForm()
      setAdminToEdit(null)
      setAdmins(updateArray(admins, res.data, "id"))
    } catch (error) {
      alert("something went wrong")
    }
  }

  const handleDelete = async (id) => {
    try {
      const res = await deleteAdmin(id)
      if (id == user.id) {
        logout()
      }
      setAdmins(admins.filter((a) => a.id !== id))
    } catch (error) {
      alert("something went wrong")
    }
  }

  return (
    <Page>
      <Container>
        <Stack sx={{ mb: 2 }} justifyContent="space-between" direction="row">
          <Typography variant="h4">المدراء</Typography>

          <Button
            onClick={() => {
              setCreateDrawerVisiblity(true)
            }}
            startIcon={<Icon icon={PlusFill} />}
            variant="contained"
          ></Button>
        </Stack>

        {renderAdmins()}

        <Drawer
          open={createDrawerIsVisible}
          onClose={() => {
            setCreateDrawerVisiblity(false)
          }}
          PaperProps={{
            sx: {
              minWidth: 500,
              padding: 4,
              border: "none",
              overflow: "hidden",
            },
          }}
        >
          <Typography variant="h4" sx={{ mb: 5 }}>
            اضافة
          </Typography>

          <FormikWrapper
            hideSubmitButton
            schema={schema}
            values={{ isAdmin: false, firstname: "", lastname: "", email: "" }}
            handleSubmit={handleCreate}
          >
            {({ values, getFieldProps, isSubmitting, getInputProps, ...fkProps }) => (
              <Stack spacing={4}>
                <TextField type="text" label="الاسم" fullWidth {...getInputProps("firstname")} />

                <TextField type="text" label="اللقب" fullWidth {...getInputProps("lastname")} />

                <TextField type="email" label="البريد الالكتروني" fullWidth {...getInputProps("email")} />

                <TextField
                  type="password"
                  label="كلمة المرور"
                  fullWidth
                  error={fkProps.touched.password && fkProps.errors.password}
                  {...getFieldProps("password")}
                />

                {values.password && values.password.trim() != "" && (
                  <Stack direction="row" spacing={1}>
                    {/* <Typography>{values.password}</Typography> */}
                    <Typography>
                      {values.password.match(charsRegex) ? " ✅ " : " ❌ "}
                      {charactersCount + " "}
                      characters
                    </Typography>
                    <Typography>
                      {values.password.match(upperCaseRegex) ? " ✅ " : " ❌ "}
                      UpperCase
                    </Typography>
                    <Typography>
                      {values.password.match(numberRegex) ? " ✅ " : " ❌ "}
                      Number
                    </Typography>
                  </Stack>
                )}

                <FormControlLabel control={<Checkbox {...getInputProps("isAdmin")} checked={values.isAdmin} />} label="super admin" />

                <SubmitButton isSubmitting={isSubmitting}>اضافة</SubmitButton>
              </Stack>
            )}
          </FormikWrapper>
          {/* <AddCategoryForm
            onSuccess={(newCategory) => {
              setCreateDrawerVisiblity(false);
              setAdmins([...admins, newCategory]);
            }}
          /> */}
        </Drawer>
        <Drawer
          open={adminToEdit ? true : false}
          onClose={() => {
            setAdminToEdit(null)
          }}
          PaperProps={{
            sx: {
              minWidth: 500,
              padding: 4,
              border: "none",
              overflow: "hidden",
            },
          }}
        >
          <Typography variant="h4" sx={{ mb: 5 }}>
            تعديل
          </Typography>

          <FormikWrapper
            hideSubmitButton
            schema={updateSchema}
            values={{
              firstname: adminToEdit?.firstname ?? "",
              lastname: adminToEdit?.lastname ?? "",
              email: adminToEdit?.email ?? "",
              isAdmin: adminToEdit?.roles[0].id === 1 ?? false,
            }}
            handleSubmit={handleUpdate}
          >
            {({ values, isSubmitting, getInputProps }) => (
              <Stack spacing={4}>
                <TextField type="text" label="الاسم" fullWidth {...getInputProps("firstname")} />

                <TextField type="text" label="اللقب" fullWidth {...getInputProps("lastname")} />

                <TextField type="email" label="البريد الالكتروني" fullWidth {...getInputProps("email")} />

                <FormControlLabel control={<Checkbox {...getInputProps("isAdmin")} checked={values.isAdmin} />} label="super admin" />

                <SubmitButton isSubmitting={isSubmitting}>تحديث</SubmitButton>
              </Stack>
            )}
          </FormikWrapper>
        </Drawer>
      </Container>
    </Page>
  )
}
