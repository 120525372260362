// material
import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import TableWrapper from 'src/components/TableWrapper';
import {
  getActiveSubscription,
  getSubscripitonStatusString,
  isExpired,
  storeIsExpired,
} from 'src/lib/subscription';
import { getSales } from 'src/utils/AdminApi';
import { StatsItem } from './StatsItem';
import { getDaysDifference } from 'src/utils/date';
// components

export default function GeneralStatsSection() {
  const { data: res } = useQuery('sales', () => getSales(), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const tenants = res?.data?.tenants ?? [];
  const sitesCount = tenants.length;
  const totalIncome = _.sumBy(tenants, (t) =>
    _.sumBy(t.payments, (p) => p.amount)
  );
  const subscripedStores = tenants.filter((t) =>
    t.payments.some((p) => p.amount > 0 && p.status === 'confirmed')
  );

  const activeStores = subscripedStores.filter((s) => !storeIsExpired(s));
  const numberOfSubscripedStores = subscripedStores.length;

  return (
    <>
      <Grid spacing={2} container>
        <StatsItem label='عدد المتاجر المسجلة' value={sitesCount} />
        <StatsItem label='مجموع الدخل' value={`${totalIncome} دينار`} />
        <StatsItem label='عدد الاشتراكات' value={numberOfSubscripedStores} />
        <StatsItem label='الاشتراكات الفعالة' value={activeStores.length} />
      </Grid>

      <Stack mt={4} justifyContent='space-between' direction='row'>
        <h3>المتاجر</h3>
        <Link
          style={{ fontSize: 32, textDecoration: 'none' }}
          to={`/tenants/create`}
        >
          +
        </Link>
      </Stack>

      <TableWrapper
        align='start'
        headerColumns={['name', 'paid', 'expiration', 'remaining', 'اخر دخول']}
        dataRows={subscripedStores.map((s) => [
          <Link
            style={{ textDecoration: 'none', color: '#0D6659' }}
            to={`/tenants/${s.id}`}
          >
            {s.site_name}
          </Link>,
          _.sumBy(s.payments, (p) => p.amount),
          getExpirationStatus(s),
          getSubscripitonStatusString(s),
          Math.abs(getDaysDifference(s.lastLoginAt)),
        ])}
      />
    </>
  );
}

const getExpirationStatus = (t) => {
  const dateString = getActiveSubscription(t)?.expiration_date;
  const diff = getDaysDifference(dateString);
  let icon = '✅';
  if (isExpired(dateString)) icon = '❌ ';
  else if (diff <= 10) icon = '⚠️';

  return `${icon}`;
  //   return `${dateString} ${icon}`;
};
