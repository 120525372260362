import { Icon } from "@iconify/react"

const getIcon = ({ name, color, size = 22 }) => <Icon icon={name} color={color} width={size} height={size} />
const getImageIcon = (path) => <img src={path} width={22} height={22} />

const sidebarConfig = [
  {
    title: "الرئيسية",
    path: "/",
    icon: getIcon({ name: "mynaui:home" }),
    onlyAdmin: false,
    tabIndex: 0,
  },
  {
    title: "الفواتير",
    path: "/bills",
    icon: getIcon({ name: "tdesign:bill" }),
    onlyAdmin: true,
    tabIndex: 1,
  },
  {
    title: "متجر جديد",
    path: "/tenants/create",
    icon: getIcon({ name: "basil:add-solid", color: "#1A705F", size: 40 }),
    onlyAdmin: false,
    tabIndex: 2,
  },
  {
    title: "المتاجر",
    path: "/tenants",
    icon: getIcon({ name: "uil:database" }),
    onlyAdmin: true,
    tabIndex: 3,
  },
  {
    title: "احصائيات الاستخدام",
    path: "/stats",
    icon: getIcon({ name: "ic:outline-query-stats" }),
    onlyAdmin: true,
  },
  {
    title: "متاجر غير نشطة",
    path: "/inactive-tenants",
    icon: getIcon({
      name: "material-symbols-light:airplanemode-inactive-rounded",
    }),
  },
  {
    title: "الادارة",
    path: "/users",
    icon: getIcon({ name: "eos-icons:admin-outlined" }),
    onlyAdmin: true,
  },
  {
    title: "حسابي",
    path: "/me",
    icon: getIcon({ name: "gg:profile" }),
    onlyAdmin: false,
  },
  {
    title: "links",
    path: "/links",
    icon: getImageIcon("/static/icons/home-home-6.svg"),
    onlyAdmin: true,
  },
  {
    title: "متاجر حسب الفترة",
    path: "/new",
    icon: getIcon({ name: "tabler:calendar-stats" }),
    onlyAdmin: true,
  },
  {
    title: "الاحصائيات+",
    path: "/statistics",
    icon: getIcon({ name: "gg:profile" }),
    onlyAdmin: true,
  },
  {
    title: "الاشعارات",
    path: "/notifications",
    icon: getImageIcon("/static/icons/notification.png"),
    onlyAdmin: true,
  },
  {
    title: "cache",
    path: "/cache",
    icon: getImageIcon("/static/icons/category-category.svg"),
    onlyAdmin: true,
  },
  {
    title: "monitor",
    path: "/monitor",
    icon: getImageIcon("/static/icons/home-home-6.svg"),
    onlyAdmin: true,
  },
]

export default sidebarConfig
