// routes
import Router from "./routes"
// theme
import ThemeConfig from "./theme"
// css
import "./App.css"
// components
// Create rtl cache
import { QueryClient, QueryClientProvider } from "react-query"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import RTL from "./theme/RTL"
import ServiceWorkerWrapper from "./ServiceWorkerWrapper"

////////////////////////////////////////
const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export default function App() {
  return (
    <QueryClientProvider client={reactQueryClient}>
      <ThemeConfig>
        <RTL>
          <ToastContainer
            position="bottom-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Router />
          <ServiceWorkerWrapper />
        </RTL>
      </ThemeConfig>
      {/* </StylesProvider> */}
    </QueryClientProvider>
  )
}
