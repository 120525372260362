import { Attributes, ContentType, ContentTypeAttributes } from "vetrina-types"
import { makeHttpRequest } from "./requestBuilder"
import qs from "qs"
import { isNullOrEmpty } from "./helperMethods"

export function strapiFindMany<T extends ContentType>(contentType: T, query?: object): Promise<Array<ContentTypeAttributes<T>>> {
  let querystring = ""
  if (!isNullOrEmpty(query)) {
    querystring = "?" + qs.stringify(query)
  }
  //@ts-ignore
  return makeHttpRequest(`/${contentType}${querystring}`)
}

export function strapiFindOne<T extends ContentType>(contentType: T, id: number | string): Promise<ContentTypeAttributes<T>> {
  //@ts-ignore
  return makeHttpRequest(`/${contentType}/${id}`)
}

export function strapiDelete<T extends ContentType>(contentType: T, id: number | string): Promise<ContentTypeAttributes<T>> {
  //@ts-ignore
  return makeHttpRequest(`/${contentType}/${id}`, "delete")
}

export function strapiUpdate<T extends ContentType>(
  contentType: T,
  id: number | string,
  updates: Partial<ContentTypeAttributes<T>>
): Promise<ContentTypeAttributes<T>> {
  //@ts-ignore
  return makeHttpRequest(`/${contentType}/${id}`, "put", updates)
}
export function strapiCreate<T extends ContentType>(contentType: T, data: Partial<ContentTypeAttributes<T>>): Promise<ContentTypeAttributes<T>> {
  //@ts-ignore
  return makeHttpRequest(`/${contentType}`, "post", data)
}
