import { Button, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import { useQuery } from 'react-query';
import DeleteAlertDialog from 'src/components/DeleteAlertDialog';
import { clearKeys, deleteKey, getAllCacheKeys } from 'src/utils/AdminApi';
import { handlePromise } from 'src/utils/toastUtils';

export default function CachePages() {
  const { data = [], refetch } = useQuery('cache', getAllCacheKeys, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleClear = async () => {
    await handlePromise(clearKeys());
    refetch();
  };
  const handleDeleteKye = async (key) => {
    await handlePromise(deleteKey(key));
    refetch();
  };
  return (
    <Container>
      <Typography variant='h2'>Storefront Cache</Typography>
      <Typography variant='body'>
        حاليا يتم تكييش محتوي الصفحة الرئيسية للمتجر لتقليص الحمل علي السرفير من
        الطلبات المتكررة لنفس المحتوي، في حالة حصول مشكلة وعدم تحديث المحتوي
        يمكن حدف الكاش الخاص بالمتجر لكي يتم قراءة التحديثات من السيرفر او حدف
        كل الكاش لكل المتاجر -(عادي)
      </Typography>
      <Button onClick={handleClear}>delete all</Button>
      <Stack my={2}>
        {data.map((item) => {
          return (
            <Stack direction='row' justifyContent='space-between' key={item}>
              <Typography>{item}</Typography>
              <DeleteAlertDialog onDelete={() => handleDeleteKye(item)} />
            </Stack>
          );
        })}
      </Stack>
    </Container>
  );
}
