const levenshtein = require("fast-levenshtein")
const { normalizeArabic } = require("./stringUtils")

function tokenize(str) {
  return normalizeArabic(str).split(/\s+/)
}

function containsAllTokens(searchTokens, candidateTokens) {
  return searchTokens.every((token) => candidateTokens.includes(token))
}

function searchForBestMatch(searchStr, candidates, searchKey) {
  const searchTokens = tokenize(searchStr)
  let bestMatch = null
  let bestScore = Infinity

  candidates.forEach((candidate) => {
    const candidateTokens = tokenize(candidate[searchKey])

    if (containsAllTokens(searchTokens, candidateTokens)) {
      const distance = levenshtein.get(searchStr, candidate[searchKey])
      if (distance < bestScore) {
        bestScore = distance
        bestMatch = candidate
      }
    }
  })

  return bestMatch
}

module.exports = searchForBestMatch
