import { Button, Card, Stack, Typography } from "@mui/material"
import React from "react"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import AccordionWrapper from "src/components/AccordionWrapper"
import { makeHttpRequest } from "src/utils/requestBuilder"

export default function MonitorPage() {
  const { data, isLoading, refetch } = useQuery("monitors", () => makeHttpRequest("/monitors?_sort=id:desc"))

  if (isLoading) return "loading"

  return (
    <div>
      <Typography>Monitoring</Typography>

      <Stack spacing={2}>
        {data.map((item) => {
          return <LogItem refetch={refetch} log={item} key={item.id} />
        })}
      </Stack>
    </div>
  )
}

function LogItem({ log, refetch }) {
  return (
    <div dir="ltr">
      <Card sx={{ p: 3 }}>
        <Stack flex={1} spacing={1}>
          {/* <Typography>{log.id}</Typography> */}
          <Stack spacing={1} direction="row" justifyContent="space-between">
            <Typography variant="h6">{log.status}</Typography>
            <Button
              onClick={async (e) => {
                await makeHttpRequest("/monitors/" + log.id, "delete")
                refetch()
              }}
              color="error"
            >
              delete
            </Button>
          </Stack>

          <Stack direction="row">
            <Typography>{log.method}</Typography>
            <Typography>{log.url}</Typography>
          </Stack>
          <Typography>createdAt: {log.created_at}</Typography>
          {log.user && (
            <Stack spacing={1} direction="row" justifyContent="space-between">
              <Typography>user: {log.user?.name}</Typography>
              <Link style={{ textDecoration: "none", color: "#0D6659" }} to={`/tenants/${log.user?.OwnedWebsite}`}>
                store:{log.user?.OwnedWebsite}
              </Link>
            </Stack>
          )}
        </Stack>
        <AccordionWrapper parent="payload">
          <Card style={{ backgroundColor: "rgba(45,45,48,0.94)" }} sx={{ p: 3, color: "white", height: "800px", overflow: "auto" }}>
            <pre>
              <code>{JSON.stringify(log.payload, null, 2)}</code>
            </pre>
          </Card>
        </AccordionWrapper>
        {log.error && (
          <AccordionWrapper parent="error">
            <Card style={{ backgroundColor: "rgba(45,45,48,0.94)" }} sx={{ p: 3, color: "white", height: "800px", overflow: "auto" }}>
              <pre
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                <code>{JSON.stringify(log.error, null, 2)}</code>
              </pre>
            </Card>
          </AccordionWrapper>
        )}
      </Card>
    </div>
  )
}
