import { useState } from "react"
import PropTypes from "prop-types"
import { Icon } from "@iconify/react"
import { NavLink as RouterLink, matchPath, useLocation } from "react-router-dom"
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill"
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill"
// material
import { alpha, useTheme, experimentalStyled as styled } from "@mui/material/styles"
import { Box, List, ListItem, Collapse, ListItemText, ListItemIcon } from "@mui/material"
import useUser from "src/hooks/useUser"

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItem button disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 52,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(8.5),
  borderBottomLeftRadius: 12,
  borderTopLeftRadius: 12,
  textAlign: "start",
  // backgroundColor:'red',

  color: theme.palette.text.secondary,
  // '&:before': {
  //   top: 0,
  //   right: 0,
  //   width: 3,
  //   bottom: 0,
  //   content: "''",
  //   display: 'none',
  //   position: 'absolute',
  //   borderTopLeftRadius: 4,
  //   borderBottomLeftRadius: 4,
  //   backgroundColor: theme.palette.primary.main
  // }
}))

const ListItemIconStyle = styled(ListItemIcon)({
  width: 30,
  height: 30,
  marginLeft: 5,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
}

export function NavItem({ item, active }) {
  const theme = useTheme()
  const isActiveRoot = active(item.path)
  const { title, path, icon, info, children } = item
  const [open, setOpen] = useState(isActiveRoot)

  const handleOpen = () => {
    setOpen((prev) => !prev)
  }

  const activeRootStyle = {
    color: "primary.main",

    fontWeight: "fontWeightMedium",
    bgcolor: theme.palette.primary.light,
    // bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    // '&:before': { display: 'block' }
  }

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  }

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box component={Icon} icon={open ? arrowIosDownwardFill : arrowIosForwardFill} sx={{ width: 16, height: 16, ml: 1 }} />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item
              const isActiveSub = active(path)

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) => theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                    <ListItemText disableTypography primary={title} />
                  </ListItemIconStyle>
                </ListItemStyle>
              )
            })}
          </List>
        </Collapse>
      </>
    )
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  )
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
}

export default function NavSection({ navConfig, ...other }) {
  const { user, isAdmin } = useUser()

  const { pathname } = useLocation()
  const match = (path) => (path ? !!matchPath({ path, end: true }, pathname) : false)

  return (
    <Box pl={2} {...other}>
      <List disablePadding>
        {navConfig.map((item) => {
          if (isAdmin) return <NavItem key={item.title} item={item} active={match} />
          else {
            return !item.onlyAdmin == true ? <NavItem key={item.title} item={item} active={match} /> : null
          }
        })}
      </List>
    </Box>
  )
}
